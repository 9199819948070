import React from 'react';
import Lottie from 'lottie-react-web';
import { Container } from './styles';
import animation from '../../assets/loading.json';

export default function Loading() {
  return (
    <Container>
      {' '}
      <div>
        <Lottie
          options={{
            animationData: animation,
          }}
        />
      </div>
    </Container>
  );
}
