import * as Yup from 'yup';
import axios from 'axios';

import { validateCPFDocument, validateCNPJDocument } from './index';

export const schema = Yup.object().shape({
  name: Yup.string()
    .required('O nome é obrigatório')
    .matches(
      /(?![ ])(?!.*[ ]{2})((?:e|da|do|das|dos|de|d'|D'|la|las|el|los)\s*?|(?:[A-Za-zàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð'][^\s]*\s*?)(?!.*[ ]$))+/,
      'O nome deve ter mais que 3 letras e excluir caracteres especiais'
    ),
  email: Yup.string()
    .email('Insira um e-mail válido')
    .required('O e-mail é obrigatório'),
  phone_number: Yup.string()
    .required('O telefone é obrigatório')
    .matches(
      /(?:(?:\+|00)?(55)\s?)?(?:\(?([1-9][0-9])\)?\s?)?(?:((?:9\d|[2-9])\d{3})-?(\d{4}))/,
      'O telefone não é válido'
    ),

  ref_id: Yup.string(),
  cpf: Yup.string()
    .matches(
      /(^\d{3}\.\d{3}\.\d{3}-\d{2}$)|(^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$)/,
      'CPF/CNPJ não é válido'
    )
    .test(
      'is-cpf',
      'CPF/CNPJ inválido',
      value =>
        value && (validateCPFDocument(value) || validateCNPJDocument(value))
    )
    .required('CPF/CNPJ é obrigatorio'),
  billing_address: Yup.object().shape({
    billing_street: Yup.string().required('O endereço é obrigatório'),
    billing_street_number: Yup.string().required(
      'O número da residência é obrigatório'
    ),
    billing_neighborhood: Yup.string().required('O bairro é obrigatório'),
    billing_city: Yup.string().required('A cidade é obrigatória'),
    billing_state: Yup.string().required('O estado é obrigatório'),
    billing_country: Yup.string().required('O país é obrigatório'),
    billing_country_iso: Yup.string(),
    billing_zipcode: Yup.string()
      .test('is-cep', 'CEP Inválido', async value => {
        if (value) {
          const valueFomatted = value.replace(/\D/g, '');
          if (valueFomatted.length === 8) {
            const response = await axios.get(
              `https://viacep.com.br/ws/${value}/json`
            );

            if (response.data.erro) {
              return false;
            }
            return true;
          }
          return false;
        }
        return false;
      })
      .test('size-cep', 'CEP Incompleto', value => {
        if (value) {
          const valueFomatted = value.replace(/\D/g, '');
          if (valueFomatted.length === 8) {
            return true;
          }
          return false;
        }
        return false;
      })
      .required('CEP é obrigatorio'),
  }),
  shipping_address: Yup.object().shape({
    shipping_street: Yup.string(),
    shipping_street_number: Yup.string(),
    shipping_neighborhood: Yup.string(),
    shipping_city: Yup.string(),
    shipping_state: Yup.string(),
    shipping_country: Yup.string(),
    shipping_country_iso: Yup.string(),
    shipping_zipcode: Yup.string().test(
      'is-cep',
      'CEP Inválido',
      async value => {
        if (value) {
          const valueFomatted = value.replace(/\D/g, '');
          if (valueFomatted.length === 8) {
            const response = await axios.get(
              `https://viacep.com.br/ws/${value}/json`
            );

            if (response.data.erro) {
              return false;
            }
            return true;
          }
          return false;
        }
        return true;
      }
    ),
  }),
});

export const credit = Yup.object().shape({
  name: Yup.string()
    .required('O nome é obrigatório')
    .matches(
      /(?![ ])(?!.*[ ]{2})((?:e|da|do|das|dos|de|d'|D'|la|las|el|los)\s*?|(?:[A-Za-zàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð'][^\s]*\s*?)(?!.*[ ]$))+/,
      'O nome deve ter mais que 3 letras e excluir caracteres especiais'
    ),
  email: Yup.string()
    .email('Insira um e-mail válido')
    .required('O e-mail é obrigatório'),
  phone_number: Yup.string()
    .required('O telefone é obrigatório')
    .matches(
      /(?:(?:\+|00)?(55)\s?)?(?:\(?([1-9][0-9])\)?\s?)?(?:((?:9\d|[2-9])\d{3})-?(\d{4}))/,
      'O telefone não é válido'
    ),
  cpf: Yup.string()
    .matches(
      /(^\d{3}\.\d{3}\.\d{3}-\d{2}$)|(^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$)/,
      'CPF/CNPJ não é válido'
    )
    .test(
      'is-cpf',
      'CPF/CNPJ inválido',
      value =>
        value && (validateCPFDocument(value) || validateCNPJDocument(value))
    )
    .required('CPF/CNPJ é obrigatorio'),
  billing_address: Yup.object().shape({
    billing_street_number: Yup.string().required(
      'O número da residência é obrigatório'
    ),
    billing_street: Yup.string().required('O endereço é obrigatório'),
    billing_neighborhood: Yup.string().required('O bairro é obrigatório'),
    billing_city: Yup.string().required('A cidade é obrigatória'),
    billing_state: Yup.string().required('O estado é obrigatório'),
    billing_country: Yup.string().required('O país é obrigatório'),
    billing_country_iso: Yup.string(),
    billing_zipcode: Yup.string()
      .test('is-cep', 'CEP Inválido', async value => {
        if (value) {
          const valueFomatted = value.replace(/\D/g, '');
          if (valueFomatted.length === 8) {
            const response = await axios.get(
              `https://viacep.com.br/ws/${value}/json`
            );

            if (response.data.erro) {
              return false;
            }
            return true;
          }
          return false;
        }
        return false;
      })
      .test('size-cep', 'CEP Incompleto', value => {
        if (value) {
          const valueFomatted = value.replace(/\D/g, '');
          if (valueFomatted.length === 8) {
            return true;
          }
          return false;
        }
        return false;
      })
      .required('CEP é obrigatorio'),
  }),
  shipping_address: Yup.object().shape({
    shipping_street: Yup.string(),
    shipping_street_number: Yup.string(),
    shipping_neighborhood: Yup.string(),
    shipping_city: Yup.string(),
    shipping_state: Yup.string(),
    shipping_country: Yup.string(),
    shipping_country_iso: Yup.string(),
    shipping_zipcode: Yup.string().test(
      'is-cep',
      'CEP Inválido',
      async value => {
        if (value) {
          const valueFomatted = value.replace(/\D/g, '');
          if (valueFomatted.length === 8) {
            const response = await axios.get(
              `https://viacep.com.br/ws/${value}/json`
            );

            if (response.data.erro) {
              return false;
            }
            return true;
          }
          return false;
        }
        return true;
      }
    ),
  }),
  card_number: Yup.string()
    .min(16, 'O número do cartão é inválido, confira seus dados')
    .required('O número do cartão é obrigatório'),
  card_holder_name: Yup.string()
    .matches(
      /(?![ ])(?!.*[ ]{2})((?:e|da|do|das|dos|de|d'|D'|la|las|el|los)\s*?|(?:[A-Za-zàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð'][^\s]*\s*?)(?!.*[ ]$))+/,
      'O nome deve ter mais que 3 letras e excluir caracteres especiais'
    )
    .required('O nome é obrigatorio'),
  card_expiration_date: Yup.string()
    .matches(/\d{1,2}\/\d{2}$/, 'A data está incompleta')
    .required('O número do validade é obrigatório'),
  card_cvv: Yup.string().required(
    'O número do código de segurança é obrigatório'
  ),
  installments: Yup.string().required('O parcelamento é obrigatório'),
});

export const second = Yup.object().shape({
  CEP: Yup.string()
    .matches(/\d{5}[-]\d{3}/, 'O cep está incompleto')
    .required('O cep é obrigatório')
    .min(9, 'O número de cep é obrigatório'),
});
