import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useField, Select } from '@rocketseat/unform';
import * as MaterialDesign from 'react-icons/md';

import { Label, Message } from './styles';

export default function SelectInput({
  label,
  type,
  options,
  customMessage,
  infoSize,
  info,
  name,
  icon,
  size,
  iconStyle,
  children,
  ...rest
}) {
  const inputRef = useRef();
  const { fieldName, defaultValue = '', registerField } = useField(name);

  const renderLabel = label;
  const InfoIcon = MaterialDesign[info];
  const Icon = MaterialDesign[icon];

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <Label htmlFor={fieldName} icon={icon}>
      {/* {error && <span>{error}</span>} */}
      <Select
        options={options}
        name={fieldName}
        type={type}
        // ref={inputRef}
        id={fieldName}
        aria-label={fieldName}
        defaultValue={defaultValue}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
      />
      <span className="label">
        {renderLabel}{' '}
        <div>
          {info && <InfoIcon size={infoSize} />}
          <Message>{customMessage}</Message>
        </div>
      </span>
      {icon && <Icon size={size} style={iconStyle} />}
      {children}
    </Label>
  );
}

SelectInput.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  icon: PropTypes.string,
  info: PropTypes.string,
  type: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  customMessage: PropTypes.string,
  size: PropTypes.number,
  infoSize: PropTypes.number,
  iconStyle: PropTypes.objectOf(PropTypes.any),
  children: PropTypes.element,
};

SelectInput.defaultProps = {
  icon: '',
  customMessage: 'Hey Brother!',
  label: '',
  size: null,
  iconStyle: null,
  children: null,
  info: null,
  type: null,
  infoSize: 16,
};
