import React from 'react';
import Icon from '@alboompro/boom-components/general/icon';
import Button from '@alboompro/boom-components/general/button';
import Box from '../../components/Box';
import Footer from '../../components/Footer';

import { Container, Wrapper, Content } from './styles';

import history from '../../services/history';

export default function reCaptchaError() {
  return (
    <>
      <Container>
        <Wrapper>
          <Box
            icon={
              <Icon
                kind="bold"
                group="interface-essential"
                category="alerts"
                file="alert-triangle.svg"
                size="24"
                color="#EF5D5D"
              />
            }
            title="Pagamento não realizado."
          >
            <Content>
              <p>Infelizmente o pagamento não pode ser efetuado.</p>
              <p>
                A validação do reCAPTCHA falhou. Por favor, tente novamente.
              </p>
              <Button
                type="primary"
                onClick={() => history.goBack()}
                style={{
                  backgroundColor: '#44ADF8',
                  width: 200,
                  height: 40,
                  fontSize: 17,
                  marginTop: 60,
                }}
              >
                Tentar novamente
              </Button>
            </Content>
          </Box>
          <Footer fixed />
        </Wrapper>
      </Container>
    </>
  );
}
