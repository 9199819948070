import React from 'react';
import Icon from '@alboompro/boom-components/general/icon';

import Box from '../Box';
import Footer from '../Footer';

import { Container, Wrapper, Content } from './styles';

export default function PixExpired({ name }) {
  return (
    <>
      <Container>
        <Wrapper>
          <Box
            title="Código Pix expirado"
            icon={
              <Icon
                kind="bold"
                group="interface-essential"
                category="alerts"
                file="alert-triangle.svg"
                size="24"
                color="#EF5D5D"
              />
            }
          >
            <Content>
              <p>Infelizmente o código Pix gerado expirou.</p>
              <p>
                Entre em contato com {name} para realizar um novo pagamento.
              </p>
            </Content>
          </Box>
          <Footer fixed />
        </Wrapper>
      </Container>
    </>
  );
}

