import React from 'react';

import PropTypes from 'prop-types';

const CreditCard = ({ color, height, width }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 22 18"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 16C0.896889 16 0 15.1031 0 14V2C0 0.896889 0.896889 0 2 0H19.3333C20.4364 0 21.3333 0.896889 21.3333 2V14C21.3333 15.1031 20.4364 16 19.3333 16H2ZM1.33333 14C1.33333 14.368 1.632 14.6667 2 14.6667H19.3333C19.7013 14.6667 20 14.368 20 14V5.33333H1.33333V14ZM20 4V2C20 1.632 19.7013 1.33333 19.3333 1.33333H2C1.632 1.33333 1.33333 1.632 1.33333 2V4H20Z"
      fill={color}
    />

    <path
      d="M4.66667 9.33333C4.29867 9.33333 4 9.03467 4 8.66667C4 8.29867 4.29867 8 4.66667 8H12C12.368 8 12.6667 8.29867 12.6667 8.66667C12.6667 9.03467 12.368 9.33333 12 9.33333H4.66667Z"
      fill={color}
    />

    <path
      d="M4.66667 12.0003C4.29867 12.0003 4 11.7017 4 11.3337C4 10.9657 4.29867 10.667 4.66667 10.667H9.33333C9.70133 10.667 10 10.9657 10 11.3337C10 11.7017 9.70133 12.0003 9.33333 12.0003H4.66667Z"
      fill={color}
    />
  </svg>
);

CreditCard.propTypes = {
  color: PropTypes.string.isRequired,

  height: PropTypes.number.isRequired,

  width: PropTypes.number.isRequired,
};

export default CreditCard;

