import React from 'react';

import { Switch } from 'react-router-dom';

import { HeaderRoute } from './layout';
import NotFound from './pages/notfound';
import Main from './pages/main';
import CardSuccess from './components/CardForm/Receipt';
import InvoiceSuccess from './components/InvoiceForm/Receipt';
import PixPaid from './components/PixForm/Paid';
import PixSuccess from './components/PixForm/Receipt';
import CardError from './pages/cardError';
import InvoiceError from './pages/invoiceError';
import PixError from './pages/pixError';
import TransactionNotify from './pages/transactionNotify';
import TransactionError from './pages/transactionError';
import reCaptchaError from './pages/reCaptchaError';

export default function Routes() {
  return (
    <Switch>
      <HeaderRoute path="/:username/:orderID" exact component={Main} />
      <HeaderRoute path="/:username/button/:orderID" exact component={Main} />
      <HeaderRoute path="/card/payment/success" exact component={CardSuccess} />
      <HeaderRoute path="/card/payment/error" exact component={CardError} />
      <HeaderRoute path="/card/payment/reCaptchaError" exact component={reCaptchaError} />
      <HeaderRoute
        path="/invoice/payment/success"
        exact
        component={InvoiceSuccess}
      />
      <HeaderRoute
        path="/invoice/payment/error"
        exact
        component={InvoiceError}
      />
      <HeaderRoute path="/pix/payment/success" exact component={PixSuccess} />
      <HeaderRoute path="/pix/payment/paid" exact component={PixPaid} />
      <HeaderRoute path="/pix/payment/error" exact component={PixError} />
      <HeaderRoute
        path="/transaction/success/notify"
        exact
        component={TransactionNotify}
      />
      <HeaderRoute
        path="/transaction/payment/error"
        exact
        component={TransactionError}
      />
      <HeaderRoute exact path="*" component={NotFound} />
      <HeaderRoute exact path="/" render={NotFound} />
    </Switch>
  );
}
