import styled from 'styled-components';

export const Wrapper = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border-bottom: 1px solid #e8e8e8;
  height: 90px;
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 50px;
  width: 100%;
  height: 100%;
  max-width: 1200px;

  @media screen and (max-width: 570px) {
    padding: 0 10px;
    justify-content: center;
  }
`;

export const Logo = styled.img`
  width: 160px;
  height: 45px;

  @media screen and (max-width: 570px) {
    width: 100%;
    height: auto;
    max-width: 100px;
  }
`;

export const UserContainer = styled.div`
  display: flex;
  align-items: center;

  @media screen and (max-width: 570px) {
    > div:first-child {
      display: none;
    }
  }
`;

export const User = styled.div`
  margin-left: 20px;
`;

export const Name = styled.p`
  font-weight: 500;
  font-size: 16px;
  color: #1f1f1f;
  margin-bottom: 5px;
`;

export const Website = styled.a`
  display: block;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  color: #636363;
  text-decoration: underline;
  margin-bottom: 4px;
`;

export const DoNotHaveWebsite = styled.p`
  font-weight: 400;
  font-size: 13px;
  line-height: 100%;
  margin-bottom: 4px;
  color: #808080;
`;

export const Text = styled.a`
  padding-top: 5px;
  display: block;
  color: #3f3f3f;
  text-decoration: underline;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 9px;
  font-style: normal;
  font-weight: 500;
  line-height: 9px;
  letter-spacing: 0em;
  text-align: left;
`;

export const SeeProfile = styled.a`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  color: #37a9fb;
  text-decoration: underline;
  cursor: pointer;
`;
