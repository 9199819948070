import styled from 'styled-components';
import colors from '../../../styles/colors';

export const Body = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.moregray};
  height: 100vh;

  @media print {
    background-color: transparent;
    height: auto;
    padding: 20px;
  }

  @media screen and (max-width: 570px) {
    height: auto;
    padding: 15px;
  }
`;

export const Container = styled.div`
  text-align: center;
  width: 100%;
  max-width: 1100px;
`;

export const Header = styled.div`
  margin: 30px auto 40px;
  max-width: 420px;

  @media screen and (max-width: 570px) {
    padding: 0 10px;
    margin-bottom: 20px;
  }

  p {
    font-size: 15px;
    color: #3f3f3f;
  }

  span {
    font-weight: 500;
  }
`;

export const Content = styled.div`
  margin: 0 auto 35px;
  max-width: 500px;

  @media screen and (max-width: 570px) {
    margin: 0 auto 10px;
  }

  p {
    color: ${colors.darkgray};
    font-size: 0.9em;
    line-height: 1.5em;

    @media screen and (max-width: 570px) {
      text-align: left;
    }
  }

  .receipt {
    text-align: center;
    span {
      color: #44adf8;
      cursor: pointer;
      text-decoration: underline;
    }
  }
`;

export const Important = styled.div`
  width: 100%;
  margin: 0 auto 40px;
  background: #eef8ff;
  border-radius: 3px;
  padding: 10px 30px;

  @media screen and (max-width: 570px) {
    width: calc(100% - 30px);
    margin: 0 15px 20px;
  }

  p:first-child {
    margin-bottom: 10px;
  }

  p {
    font-size: 14px;
    color: #3f3f3f;
    text-align: center;
  }

  span {
    font-weight: 500;
  }
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto 15px;
  padding: 0 20px;

  @media screen and (max-width: 570px) {
    margin-bottom: 0;
  }
`;

export const InfoItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

export const Notification = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto 10px;
  text-align: left;
  padding: 0 20px 35px;
  border-bottom: 1px solid #ededed;

  span {
    font-weight: 500;
  }
`;

export const Footer = styled.div`
  font-size: 14px;
  color: #656565;
  max-width: 650px;
  margin: 15px auto;

  .secure {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      margin-right: 4px;
      width: 100px;
    }
  }
`;
