import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
`;

export const Image = styled.div`
  margin-bottom: 30px;
  img {
    width: 120px
  }
`;

export const Title = styled.h3`
  margin-bottom: 20px;
  font-size: 28px;
  color: #2d2d2d;
  font-weight: 400;
  margin-bottom: 10px;
`;

export const Text = styled.p`
  font-size: 18px;
  color: #272727;
`;

export const BrandAlboomPay = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 30px;
  
  p {
    font-size: 0.9em;
    padding: 0 5px;
  }
  img {
    max-width: 100%;
  }
  img.logo {
    width: 92px;
  }

`;