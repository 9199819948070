import styled from 'styled-components';

export const Container = styled.div`
  background: #fff;
  max-width: ${props => (props.width ? props.width : '100%')};
  border-radius: 5px;
  border: 1px solid #ebebeb;
`;

export const Header = styled.div`
  background: #f7f7f7;
  padding: 30px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Title = styled.div`
  font-size: 18px;
  text-align: center;
  font-weight: 500;
  color: #464646;
`;

export const IconContainer = styled.div`
  margin-right: 10px;
`;
