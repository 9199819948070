import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Icon from '@alboompro/boom-components/general/icon';
import RAvatar from 'react-avatar';
import { Wrapper, AvatarWrapper } from './styles';

const Avatar = () => {
  const [data, setData] = useState({});
  const { account } = useSelector(state => state.auth.orders);

  useEffect(() => {
    if (account) {
      setData({
        avatar: account.avatar_url,
        name: account.fullname,
        verifyStatus: account.verify_status,
      });
    }
  }, [account]);

  return (
    <Wrapper>
      {data.avatar ? (
        <div className="verifyStatus">
          <AvatarWrapper>
            <img src={data.avatar} name="avatar" alt={data.name} />
          </AvatarWrapper>
          <Icon
            kind="bold"
            group="interface-essential"
            category="form-validation"
            file="check-circle-1.svg"
            color={data.verifyStatus === 'verified' ? '#1B9DFA' : '#C4C4C4'}
          />
        </div>
      ) : (
        <div className="verifyStatus">
          <RAvatar
            name={data.name}
            size="60"
            round="50px"
            textSizeRatio={4}
            maxInitials={2}
            color="#D467E5"
          />
          <Icon
            kind="bold"
            group="interface-essential"
            category="form-validation"
            file="check-circle-1.svg"
            color={data.verifyStatus === 'verified' ? '#1B9DFA' : '#C4C4C4'}
          />
        </div>
      )}
    </Wrapper>
  );
};

export default Avatar;
