import React, { useState, useEffect } from 'react';
import { TimeDisplay } from './styles';

const Countdown = ({ initialDate, hours = 4, expirationText = "00:00:00" }) => {
  const calculateTimeLeft = () => {
    const expiration = new Date(initialDate);
    expiration.setHours(expiration.getHours() + hours).toString();

    const difference = +new Date(expiration) - +new Date();

    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        Horas: Math.floor((difference / (1000 * 60 * 60)) % 24).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false}),
        Minutos: Math.floor((difference / 1000 / 60) % 60).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false}),
        Segundos: Math.floor((difference / 1000) % 60).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false})
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  const timerComponents = [];

  Object.keys(timeLeft).forEach((interval) => {
    if (!timeLeft[interval]) {
      return;
    }

    timerComponents.push(
      <TimeDisplay>
        {timeLeft[interval]}{interval !== "Segundos" ? ":" : ""}
      </TimeDisplay>
    );
  });

  return timerComponents.length ? timerComponents : <span>{expirationText}</span>;
};

export default Countdown;
