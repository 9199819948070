import styled from 'styled-components';

export const ContainerFooter = styled.div`
  font-size: 14px;
  color: #656565;
  max-width: 650px;
  margin: 15px auto;
  text-align: center;

  &.fixed {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .secure {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      margin-right: 4px;
      width: 100px;
    }
  }
`;
