import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import Icon from '@alboompro/boom-components/general/icon';
import Box from '../../components/Box';
import Footer from '../../components/Footer';

import { Container, Wrapper, Content } from './styles';

export default function NotFound() {
  const { errors } = useSelector(state => state.auth);
  const [info, setInfo] = useState({});

  useEffect(() => {
    if (errors.message === 'id is not defined') {
      setInfo({
        title: 'Este pagamento já foi realizado.',
        icon: (
          <Icon
            kind="bold"
            group="interface-essential"
            category="form-validation"
            file="check-circle-1-alternate.svg"
            size="24"
            color="44ADF8"
          />
        ),
        content: 'Esta solicitação de pagamento já consta como paga.',
      });
    } else {
      setInfo({
        title: 'Página não encontada',
        icon: (
          <Icon
            kind="bold"
            group="interface-essential"
            category="alerts"
            file="alert-triangle.svg"
            size="24"
            color="#EF5D5D"
          />
        ),
        content: 'Esta página não existe ou não está mais válida.',
      });
    }
  }, [errors]);

  return (
    <>
      <Container>
        <Wrapper>
          <Box icon={info.icon} title={info.title}>
            <Content>{info.content}</Content>
          </Box>
          <Footer fixed />
        </Wrapper>
      </Container>
    </>
  );
}
