import styled from 'styled-components';
import colors from '../../../styles/colors';

export const Body = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f6fafe;
`;

export const Container = styled.div`
  color: ${colors.darkgray};
  text-align: center;
  width: 100%;
  max-width: 1155px;
  margin: 4em 0;
  padding: 15px;
`;

export const Content = styled.div`
  margin-bottom: 2em;
  padding: 0 210px;

  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 1024px) {
    padding: 0 100px;
  }

  @media screen and (max-width: 930px) {
    padding: 0 50px;
  }

  @media screen and (max-width: 570px) {
    padding: 0 10px;
  }
`;

export const Information = styled.div`
  width: 100%;
  padding: 25px 35px 30px;
  font-size: 14px;
  border-radius: 3px;
  margin: 60px 0 30px;

  b {
    font-weight: 500;
  }

  @media screen and (max-width: 768px) {
    margin: 30px 0 0;
  }
`;

export const Title = styled.div`
  font-size: 17px;
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 40px;

  .steps-container {
    max-width: 400px;
    margin-left: 30px;

    @media screen and (max-width: 768px) {
      max-width: fit-content;
      margin-left: 0px;
      padding-top: 20px;
    }
  }

  .steps-info {
    background: #eef8ff;
    padding: 20px 30px;

    span {
      line-height: 20px;
    }

    .steps-info-title {
      font-weight: 500;
      padding-bottom: 10px;
    }

    @media screen and (max-width: 768px) {
      margin-bottom: 20px;
    }
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    justify-content: space-between;
    padding-top: 0px;
  }
`;

export const QRCodeHeader = styled.div`
  font-weight: 500;
`;

export const QRCodeContainer = styled.div`
  display: flex;
  background: #eef8ff;
  flex-direction: column;
  padding: 55px 30px;

  img {
    width: 200px;
    height: 200px;
  }

  b {
    padding-top: 14px;
  }

  .qrcode-title {
    font-weight: 500;
    font-size: 16px;
    padding-bottom: 10px;
  }

  .qrcode-expires-in {
    color: #2e2e2e;
    font-size: 13px;
    padding-bottom: 20px;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const Step = styled.div`
  display: flex;
  flex-direction: row;

  margin-bottom: 25px;

  .step-content {
    font-size: 16px;
    margin-left: 10px;
    text-align: left;
  }

  .step-details {
    color: #757575;
    font-size: 13px;
    padding: 8px 0px;
  }

  .step-mobile {
    padding-bottom: 20px;
  }
`;

export const StepNumber = styled.div`
  color: white;
  height: 24px;
  font-weight: 700;
  font-size: 16px;

  div {
    padding: 4px 8px;
    text-align: center;
    border-radius: 4px;
    background: #44adf8;
    height: 24px;
    width: 24px;
  }
`;

export const Footer = styled.div`
  font-size: 14px;
  color: #656565;
  max-width: 650px;
  margin: 15px auto;

  .secure {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      margin-right: 4px;
      width: 100px;
    }
  }
`;
