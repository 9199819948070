import styled from 'styled-components';

import arrow from '../../assets/arrow_down.png';

import colors from '../../styles/colors';

export const Label = styled.label`
  select {
    padding: 14px 15px;
    width: 100%;
    transition: all 0.2s ease-out;
    border: 1px solid #e8e8e8;
    border-radius: 3px;
    font-size: 14px;
    line-height: 1.2;
    color: ${colors.darkgray};
    appearance: none;
    background-image: url(${arrow});
    background-position: center right 1.5rem;
    background-repeat: no-repeat;

    &:focus {
      box-shadow: 0 0 2px 1px #44adf8;
    }

    &:focus &::placeholder {
      display: none;
    }

    &:after {
      font-size: 14px;
      top: 1px;
      left: 0;
    }
  }
`;

export const Message = styled.div`
  display: none;
  position: absolute;
  background: #fff;
  color: #9c9a9a;
  font-size: 1.2rem;
  min-width: 20rem;
  left: 2.5rem;
  top: 1rem;
  padding: 1rem;
  border-radius: 3px;
  box-shadow: 0 0 7px 0 rgba(6, 6, 6, 0.08);
`;
