import produce from 'immer';

const INITIAL_STATE = {
  payment_type: null,
  fullname: '',
  email: '',
  country_iso: 'br',
  ref_id: '',
  phone: '',
  cpf: '',
  billing_zipcode: '',
  billing_street: '',
  billing_street_number: '',
  billing_neighborhood: '',
  billing_complement: '',
  billing_city: '',
  billing_state: '',
  shipping: 'same',
  shipping_zipcode: '',
  shipping_street: '',
  shipping_street_number: '',
  shipping_neighborhood: '',
  shipping_complement: '',
  shipping_city: '',
  shipping_state: '',
};

export default function payment(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@payer/SET_PAYER': {
        Object.keys(action.payload).forEach(function eachKey(key) {
          draft[key] = action.payload[key];
        });
        break;
      }
      default:
        break;
    }
  });
}
