import React from 'react';
import { ContainerFooter } from './styles';

const Footer = ({ fixed = false }) => {
  return (
    <ContainerFooter className={fixed && 'fixed'}>
      <p>
        O Alboom Pay é 100% seguro. Processamos o pagamento através do Pagar.me,
        empresa do grupo Stone e líder em pagamentos online no Brasil.
      </p>

      <p className="secure">
        <img
          className="logo"
          src="https://bifrost.alboompro.com/products/alboompay/AlboomPay2022-full.svg"
          alt="AlboomPay"
        />
        | Pagamentos online 100% seguro
      </p>
    </ContainerFooter>
  );
};

export default Footer;
