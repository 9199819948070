import styled from 'styled-components';
import media from '../../styles/media';

export const AvatarWrapper = styled.div`
  width: 61px;
  height: 61px;
  overflow: hidden;
  border-radius: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border: 1px solid #e8e9e8;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0;
  }

  @media ${media.small} {
  }

  @media ${media.smallest} {
  }
`;

export const Wrapper = styled.div`
  z-index: 1;
  width: 60px;

  img {
    max-width: 100%;
  }

  > div div span {
    font-size: 19px;
  }

  > div.verifyStatus {
    position: relative;
  }

  > div.verifyStatus > img {
    position: absolute;
    bottom: 0px;
    right: -2px;
    width: 20px;
    height: 20px;
    background-color: #ffffff;
    border-radius: 50%;
    border: 1px solid #ffffff;
  }
`;
