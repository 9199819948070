import produce from 'immer';

const INITIAL_STATE = {
  loading: false,
  orders: {},
  errors: {}
};

export default function auth(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@auth/SIGN_IN_REQUEST': {
        draft.loading = true;
        break;
      }
      case '@auth/SIGN_IN_SUCCESS': {
        draft.loading = false;
        draft.orders = action.payload.order.orders;
        break;
      }
      case '@auth/SIGN_FAILURE': {
        draft.loading = false;
        draft.errors = action.payload.message;
        break;
      }
      default:
        break;
    }
  });
}
