import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Currency from 'react-currency-formatter';
import Icon from '@alboompro/boom-components/general/icon';
import Load from '../../components/Loading';
import Box from '../../components/Box';
import Footer from '../../components/Footer';

import history from '../../services/history';

import { Container, Body, Content } from './styles';

export default function Receipt() {
  const {
    loading,
    receipt: { transaction: paymentData },
  } = useSelector(state => state.payment);

  useEffect(() => {
    if (!paymentData) {
      history.push('/');
    }
  }, [paymentData]);

  return (
    <Body>
      {loading || !paymentData ? (
        <Load />
      ) : (
        <Container>
          <Box
            title="O Pagamento está sendo processado."
            icon={
              <Icon
                kind="bold"
                group="interface-essential"
                category="time"
                file="time-clock-circle-alternate.svg"
                size="32"
                color="#44ADF8"
              />
            }
          >
            <Content>
              <p>
                O pagamento de{' '}
                <span>
                  <Currency
                    quantity={paymentData.amount / 100}
                    decimal=","
                    currency="BRL"
                  />{' '}
                </span>
                para <span>{paymentData.billing.name}</span> está em transação.
              </p>
              <p>
                Enviaremos um e-mail para {paymentData.customer.email} assim que
                tivermos mais informações sobre seu pagamento.
              </p>
            </Content>
          </Box>
          <Footer fixed />
        </Container>
      )}
    </Body>
  );
}
