/* eslint-disable no-empty */
/* eslint-disable camelcase */
import React, { useEffect, useState, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ReCAPTCHA from "react-google-recaptcha";
// import { Spinner } from 'react-activity';
import ReactTooltip from 'react-tooltip';
import 'react-activity/dist/react-activity.css';
// import Button from '../Button';
import Button from '@alboompro/boom-components/general/button';
import Select from '../Select';
import CheckBox from '../CheckBox';
import Address from '../Address';
import InputMask from '../InputMask';
import masks, {
  maskPhone,
  maskCPForCNPJ,
  maskCVV,
  maskExpiry,
} from '../../utils/masks';
import utils from '../../utils';
import info from '../../assets/info.png';
import cvv from '../../assets/cvv.png';
import RadioInput from '../RadioInput';
import {
  paymentIsIframe,
  paymentRequest,
} from '../../store/modules/payment/actions';
import { setPayer } from '../../store/modules/payer/actions';
import { credit } from '../../validators/schemas';
import CardFlags from '../CardFlags';

import {
  Container,
  RowWrapper,
  StyledForm,
  StyledInput,
  Col,
  RowCenter,
  Label,
  Title,
  RowLeft,
  UserTermsText,
} from '../../styles/form';

export default function Form() {
  const recaptchaRef = React.createRef();
  const payer = useSelector(state => state.payer);
  const loading = useSelector(state => state.payment.loading);
  const order = useSelector(state => state.auth.orders);
  const { search } = useLocation();
  const [cardNumber, setCardNumber] = useState('');
  const [cvvNumber, setCvvNumber] = useState('');
  const [expiryDate, setExpiryDate] = useState('');
  const [terms, setTerms] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (order) {
      const { payers } = order;
      if (payers) {
        dispatch(
          setPayer({
            fullname: payers.name,
            email: payers.email,
            ref_id: payers.ref_id,
          })
        );
      }
    }
  }, [order, dispatch]);

  async function handleSubmit(data) {
    // eslint-disable-next-line radix
    const refToken = await recaptchaRef.current.executeAsync()
    if (refToken) {
      data.installments = parseInt(data.installments || '1');
      data.reCaptchaToken = refToken || '';
      dispatch(paymentRequest({ paymentForm: data, ...order }));
    }
  }

  function handleCard(value) {
    setCardNumber(value);
  }

  useEffect(() => {
    const searchParams = new URLSearchParams(search);
    const queryParam = searchParams.get('iframe');
    if (queryParam && window.parent) dispatch(paymentIsIframe(true));
  }, []);

  return (
    <>
      <Container>
        <StyledForm schema={credit} onSubmit={handleSubmit}>
          <RowWrapper>
            <Title>Seus dados</Title>
          </RowWrapper>

          <RowWrapper>
            <Label>Nome Completo ou Razão Social</Label>
            <StyledInput
              name="name"
              placeholder=""
              value={payer.fullname}
              onChange={e => dispatch(setPayer({ fullname: e.target.value }))}
            />
          </RowWrapper>

          <RowWrapper>
            <Label>Email</Label>
            <StyledInput
              name="email"
              placeholder=""
              value={payer.email}
              onChange={e => dispatch(setPayer({ email: e.target.value }))}
            />
          </RowWrapper>

          <RowWrapper>
            <Label>Telefone</Label>
            <StyledInput
              type="text"
              inputMask={masks.phone}
              name="phone_number"
              placeholder="( ) ____-____"
              className="mask"
              value={payer.phone}
              onChange={e =>
                dispatch(setPayer({ phone: maskPhone(e.target.value) }))
              }
            />
          </RowWrapper>

          <RowWrapper>
            <Label>CPF/CNPJ</Label>
            <StyledInput
              inputMask={masks.cpf}
              name="cpf"
              placeholder=""
              className="mask"
              value={payer.cpf}
              onChange={e =>
                dispatch(setPayer({ cpf: maskCPForCNPJ(e.target.value) }))
              }
            />
          </RowWrapper>

          <RowWrapper>
            <Title>Dados do cartão</Title>
          </RowWrapper>

          <RowWrapper>
            <Label>Numero do cartão</Label>
            <InputMask
              data-private
              inputMask={masks.cartão}
              guide={false}
              name="card_number"
              className="mask"
              placeholder="0000 0000 0000 0000"
              value={cardNumber}
              onChange={e => handleCard(e.target.value)}
            />
            <CardFlags number={cardNumber} />
          </RowWrapper>

          <RowWrapper>
            <Label>Nome como aparece no cartão</Label>
            <StyledInput data-private name="card_holder_name" placeholder="" />
          </RowWrapper>

          <RowWrapper>
            <Col width="30%" mr="2%">
              <Label>Validade</Label>
              <StyledInput
                data-private
                value={expiryDate}
                onChange={e => setExpiryDate(maskExpiry(e.target.value))}
                name="card_expiration_date"
                className="mask"
                placeholder="MM/AA"
              />
            </Col>

            <Col width="30%" width_mb="85%">
              <Label>CVV</Label>
              <StyledInput
                data-private
                type="text"
                value={cvvNumber}
                onChange={e => setCvvNumber(maskCVV(e.target.value))}
                name="card_cvv"
                placeholder=""
                className="mask"
              />
            </Col>

            <Col width="30%" width_mb="10%">
              <div className="tooltip">
                <img data-tip data-for="cvv-tooltip" src={info} alt="cvv" />
              </div>
              <ReactTooltip
                id="cvv-tooltip"
                place="right"
                type="light"
                effect="solid"
              >
                <Col width="40%" width_mb="40%" mr="2%">
                  <img src={cvv} alt="cvv" />
                </Col>
                <Col width="58%" width_mb="58%">
                  <p>
                    O CVV são os 3 números que estão no verso do seu cartão.
                  </p>
                </Col>
              </ReactTooltip>
            </Col>
          </RowWrapper>

          <RowWrapper>
            <Label>Parcelamento</Label>
            {order.installments && (
              <Select
                name="installments"
                options={order.installments.map(i => ({
                  id: i.installment,
                  title: `${i.installment}x ${utils.currencyFormat(
                    i.installment_amount / 100
                  )} = ${utils.currencyFormat(i.amount / 100)}${order.amount === i.amount ? ' sem acréscimo' : ''
                    }`,
                }))}
                placeholder="Selecione a forma de parcelamento"
              />
            )}
          </RowWrapper>

          <RowWrapper>
            <Title>Endereço de faturamento</Title>
          </RowWrapper>
          <Address namePrefix="billing" />

          {!!order.shipping_enabled && (
            <RowWrapper>
              <Title>Endereço de entrega</Title>
              <RowWrapper>
                <Col width="60%">
                  <RadioInput
                    name="same_delivery_address"
                    type="radio"
                    value="same"
                    label="  "
                    checked={payer.shipping === 'same'}
                    onChange={e =>
                      dispatch(setPayer({ shipping: e.target.value }))
                    }
                  >
                    <p>Usar o mesmo endereço de faturamento</p>
                  </RadioInput>
                </Col>
                <Col width="35%">
                  <RadioInput
                    name="other_delivery_address"
                    type="radio"
                    value="other"
                    checked={payer.shipping === 'other'}
                    label="  "
                    onChange={e =>
                      dispatch(setPayer({ shipping: e.target.value }))
                    }
                  >
                    <p>Usar outro endereço</p>
                  </RadioInput>
                </Col>
              </RowWrapper>
              {payer.shipping === 'other' && <Address namePrefix="shipping" />}
            </RowWrapper>
          )}

          <RowLeft>
            <CheckBox
              name="terms"
              label="  "
              required
              onChange={() => setTerms(!terms)}
            >
              <UserTermsText>
                Li e aceito os{' '}
                <a
                  href="https://www.alboompro.com/terms-of-use/"
                  target="blank"
                >
                  termos de uso
                </a>
                .
              </UserTermsText>
            </CheckBox>
          </RowLeft>

          <RowCenter>
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey={process.env.REACT_APP_G_RECAPTCHA_SITE_KEY}
              size="invisible"
            />
            <Button
              type="primary"
              htmlType="submit"
              style={{
                backgroundColor: '#44ADF8',
                width: 350,
                height: 60,
                fontSize: 17,
              }}
              loading={loading}
              disabled={loading}
            >
              Confirmar o pagamento
            </Button>
          </RowCenter>
        </StyledForm>
      </Container>
    </>
  );
}
