/* eslint-disable no-console */
import { takeLatest, call, all, put, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import history from '../../../services/history';
import api from '../../../services/api';

import {
  paymentSuccess,
  paymentFailure,
  invoiceNotifyFailure,
  invoiceNotifySuccess,
} from './actions';

function extractNumbers(string) {
  return string.replace(/\D/g, '');
}

// eslint-disable-next-line consistent-return
export function* paymentSend({ payload }) {
  try {
    const { sendData } = payload;

    const items = sendData.order_items.map((item, index) => ({
      id: String(index),
      title: item.description || item.item,
      unit_price: item.amount,
      quantity: item.quantity,
      tangible: sendData.shipping_address_required,
    }));

    let card = {};

    if (sendData.paymentForm && sendData.paymentForm.card_number) {
      card = {
        card_number: extractNumbers(sendData.paymentForm.card_number),
        card_cvv: sendData.paymentForm.card_cvv,
        card_expiration_date: extractNumbers(
          sendData.paymentForm.card_expiration_date
        ),
        card_holder_name: sendData.paymentForm.card_holder_name,
      };
    }

    const paymentData = {
      reCaptchaToken: sendData.paymentForm.reCaptchaToken,
      amount: sendData.amount,
      payment_method: sendData.paymentForm.payment_method,
      ...card,
      customer: {
        external_id: sendData.paymentForm.ref_id,
        name: sendData.paymentForm.name,
        type:
          extractNumbers(sendData.paymentForm.cpf).length === 11
            ? 'individual'
            : 'corporation',
        country: 'br',
        email: sendData.paymentForm.email,
        documents: [
          {
            type:
              extractNumbers(sendData.paymentForm.cpf).length === 11
                ? 'cpf'
                : 'cnpj',
            number: extractNumbers(sendData.paymentForm.cpf),
          },
        ],
        phone_numbers: [
          `+55${extractNumbers(sendData.paymentForm.phone_number)}`,
        ],
      },
      items,
      installments: sendData.paymentForm.installments,
      soft_descriptor: sendData.account.fullname.substring(0, 13),
    };

    if (sendData.paymentForm.billing_address) {
      paymentData.billing = {
        name: sendData.paymentForm.name,
        address: {
          country: sendData.paymentForm.billing_address.billing_country_iso,
          state: sendData.paymentForm.billing_address.billing_state,
          city: sendData.paymentForm.billing_address.billing_city,
          neighborhood:
            sendData.paymentForm.billing_address.billing_neighborhood,
          street: sendData.paymentForm.billing_address.billing_street,
          street_number:
            sendData.paymentForm.billing_address.billing_street_number,
          zipcode:
            sendData.paymentForm.billing_address.billing_zipcode &&
            sendData.paymentForm.billing_address.billing_zipcode.replace(
              /[^0-9.]/g,
              ''
            ),
        },
      };
    }

    if (sendData.paymentForm.shipping_address.shipping_zipcode) {
      paymentData.shipping = {
        name: sendData.paymentForm.name,
        fee: 1000,
        expedited: true,
        address: {
          country: sendData.paymentForm.shipping_address.shipping_country_iso,
          state: sendData.paymentForm.shipping_address.shipping_state,
          city: sendData.paymentForm.shipping_address.shipping_city,
          neighborhood:
            sendData.paymentForm.shipping_address.shipping_neighborhood,
          street: sendData.paymentForm.shipping_address.shipping_street,
          street_number:
            sendData.paymentForm.shipping_address.shipping_street_number,
          zipcode:
            sendData.paymentForm.shipping_address.shipping_zipcode &&
            sendData.paymentForm.shipping_address.shipping_zipcode.replace(
              /[^0-9.]/g,
              ''
            ),
        },
      };
    }

    const { data: response } = yield call(
      api.post,
      `/pay-order-link/${sendData.id}`,
      paymentData
    );

    const { data } = response;
    const { customer } = data.transaction;

    if (customer) {
      const user = {
        id: customer.id,
        name: customer.name,
        email: customer.email,
      };
    }

    const successStatus = ['paid', 'waiting_payment'];

    if (
      !response.success ||
      !successStatus.includes(response.data.transaction.status)
    ) {
      throw response;
    }

    yield put(paymentSuccess(data));

    const isiframe = yield select(store => store.payment.isIframe);

    if (isiframe) {
      window.parent.postMessage({ data, source: 'alboompay' }, '*');
      return;
    }

    if (data.notification_type === 'email') {
      return history.push('/transaction/success/notify');
    }

    if (paymentData.payment_method === 'boleto') {
      return history.push('/invoice/payment/success');
    }

    if (paymentData.payment_method === 'pix') {
      return history.push('/pix/payment/success');
    }

    return history.push('/card/payment/success');
  } catch (err) {
    console.log(err);

    yield put(paymentFailure());

    if (err.response.data.data.error === 'Invalid reCAPTCHA.') {
      return history.push('/card/payment/reCaptchaError');
    }

    if (
      err.data &&
      err.data.transaction &&
      err.data.transaction.payment_method
    ) {
      if (err.data.transaction.payment_method === 'boleto') {
        return history.push('/invoice/payment/error');
      }
      if (err.data.transaction.payment_method === 'pix') {
        return history.push('/pix/payment/error');
      }
      return history.push('/card/payment/error');
    }

    return history.push('/transaction/payment/error');
  }
}

// eslint-disable-next-line camelcase
export function* paymentNotify({ payload: { date, order_id } }) {
  try {
    const { data: response } = yield call(
      api.post,
      // eslint-disable-next-line camelcase
      `/notify-order/${order_id}`,
      {
        date,
      }
    );
    yield put(invoiceNotifySuccess(response.data));
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err.response);
    yield put(invoiceNotifyFailure());
    toast.error(
      'Erro ao enviar notificação, por favor tente novamente mais tarde. '
    );
  }
}

export default all([
  takeLatest('@payment/PAYMENT_REQUEST', paymentSend),
  takeLatest('@payment/INVOICE_NOTIFY', paymentNotify),
]);
