import produce from 'immer';

const INITIAL_STATE = {
  loading: false,
  receipt: {},
  notification: '',
  isIframe: false,
};

export default function payment(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@payment/PAYMENT_REQUEST': {
        draft.loading = true;
        break;
      }
      case '@payment/PAYMENT_SUCCESS': {
        draft.loading = false;
        const { payload } = action;
        draft.receipt = payload.sendData;
        break;
      }
      case '@payment/INVOICE_NOTIFY': {
        draft.loading = true;
        break;
      }
      case '@payment/INVOICE_NOTIFY_SUCCESS': {
        draft.loading = false;
        const { payload } = action;
        draft.notification = payload.notification;
        break;
      }
      case '@payment/INVOICE_NOTIFY_FAILURE':
      case '@payment/PAYMENT_FAILURE':
        draft.loading = false;
        break;
      case '@payment/IS_IFRAME':
        draft.isIframe = action.payload;
        break;
      default:
    }
  });
}
