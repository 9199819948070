import styled from 'styled-components';

export const Container = styled.div`
  height: calc(100vh - 90px);
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 50px 0 0;
`;

export const Wrapper = styled.div`
  max-width: 1100px;
  width: 100%;
  height: auto;
  padding: 0 15px;
`;

export const Content = styled.div`
  padding: 40px 0;
  text-align: center;
  font-size: 15px;
  color: #3f3f3f;
`;
