import { validateCPF } from './Documents/CPF';
import { validateCNPJ } from './Documents/CNPJ';

export const validateCPFDocument = cpf => {
  return validateCPF(cpf);
};

export const validateCNPJDocument = cpf => {
  return validateCNPJ(cpf);
};
