import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useField } from '@rocketseat/unform';
import * as FontAwesome from 'react-icons/md';
import { Label, Message } from './styles';

export default function RadioInput({
  label,
  type,
  customMessage,
  infoSize,
  value,
  info,
  name,
  icon,
  size,
  iconStyle,
  children,
  checked,
  onChange,
  hideInput,
  ...rest
}) {
  const ref = useRef(null);
  const { fieldName, registerField, defaultValue, error } = useField(name);
  const InfoIcon = FontAwesome[info];

  useEffect(() => {
    if (!ref.current) return;
    registerField({
      name: fieldName,
      ref: ref.current,
      path: 'value',
    });
  }, [ref.current, fieldName]); //eslint-disable-line

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Label className="container" htmlFor={fieldName} icon={icon} {...rest}>
      {error && <span>{error}</span>}
      <input
        value={value}
        name={fieldName}
        type={type}
        ref={ref}
        id={fieldName}
        aria-label={fieldName}
        defaultValue={defaultValue}
        checked={checked}
        onChange={onChange}
      />
      <span className="checkmark" style={{ display: hideInput && 'none' }} />
      <span className="label">
        {children}
        <div>
          {info && <InfoIcon size={infoSize} />}
          {customMessage && <Message>{customMessage}</Message>}
        </div>
      </span>
    </Label>
  );
}

RadioInput.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  icon: PropTypes.string,
  info: PropTypes.string,
  type: PropTypes.string,
  customMessage: PropTypes.string,
  size: PropTypes.number,
  infoSize: PropTypes.number,
  iconStyle: PropTypes.objectOf(PropTypes.any),
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  // eslint-disable-next-line react/require-default-props
  defaultValue: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  hideInput: PropTypes.bool,
};

RadioInput.defaultProps = {
  icon: '',
  customMessage: 'Hey Brother!',
  label: '',
  size: null,
  iconStyle: null,
  children: null,
  info: '',
  type: '',
  infoSize: 16,
  value: '',
  checked: false,
  onChange: () => {},
  hideInput: false,
};
