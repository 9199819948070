import React from 'react';
import { Container, Image, Title, Text, BrandAlboomPay } from './styles';
import cadeado from '../../assets/vector-smart-object-copy-3.png';

export default function LinkPaid() {
  return (
    <Container>
      <Image>
        <img
          src="https://icons.alboompro.com/v1/bold/interface-essential/form-validation/46ae70/check-circle-1-alternate.svg"
          alt="Sucesso"
        />
      </Image>
      <Title>Este link de pagamento já foi pago</Title>
      <Text>O link de pagamento que você acessou já está pago.</Text>
      <BrandAlboomPay>
        <img src={cadeado} alt="AlboomPay" />
        <p>Pagamento seguro com</p>
        <img
          className="logo"
          src="https://bifrost.alboompro.com/products/alboompay/AlboomPay2022-full.svg"
          alt="AlboomPay"
        />
      </BrandAlboomPay>
    </Container>
  );
}
