/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable camelcase */
/* eslint-disable no-return-assign */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Currency from 'react-currency-formatter';
import Icon from '@alboompro/boom-components/general/icon';
import Box from '../Box';
import {
  Container,
  Details,
  Title,
  Account,
  AccountContainer,
  Description,
  TotalContainer,
  Total,
  ItemContainer,
  Item,
  ItemPrice,
  Info,
  Security,
  AccountSubContainer,
} from './styles';

export default function Sidebar({ fixed, setIsVisible }) {
  const [paymentData, setPaymentData] = useState({
    name: '',
    display_name: '',
    email: '',
    doc: '',
    subtotal: 0,
    discount: 0,
    shipping_amount: 0,
    type: null,
    description: '',
  });

  const [items, setItems] = useState({});
  const order = useSelector(state => state.auth.orders);

  useEffect(() => {
    if (order.id) {
      const {
        account,
        order_items,
        discount,
        shipping_amount,
        type,
        description,
        amountTotal,
      } = order;

      const subTotalItems = order_items.map((item, index) => ({
        ...item,
        id: index,
        subtotal: item.quantity * (item.amount / 100),
      }));

      setItems(subTotalItems);

      setPaymentData({
        name: account.fullname,
        display_name: account.display_name,
        email: account.email,
        doc: account.doc,
        account_type: account.type,
        phone: account.phone,
        subtotal: order.amount / 100,
        discount: discount / 100,
        total: order.amountTotal / 100,
        shipping_amount: shipping_amount / 100,
        type_account: account.type,
        type,
        description,
        amountTotal,
      });
    }
  }, [order]);

  const { doc } = paymentData;
  // const account_type = paymentData.account_type;
  // console.log(paymentData);

  const docFormatted = doc
    ? doc.length === 11
      ? `${doc.substr(0, 3)}.${doc.substr(3, 3)}.${doc.substr(
          6,
          3
        )}-${doc.substr(9, 2)}`
      : `${doc.substr(0, 2)}.${doc.substr(2, 3)}.${doc.substr(
          5,
          3
        )}/${doc.substr(8, 4)}-${doc.substr(12, 2)}`
    : 'Carregando...';

  return (
    <Container fixed={fixed}>
      <Box title="Resumo de Pagamento">
        <Details>
          <AccountContainer>
            <Title>Pagamento para</Title>
            {/* {account_type == 'person' ? ( */}
            <Account className="name">
              {paymentData.account_type && paymentData.account_type === 'person'
                ? paymentData.name
                : paymentData.display_name}
            </Account>
            {/* // ) : (
            //   <Account className="name">{paymentData.name}</Account>
            // )} */}
            {paymentData.account_type && paymentData.account_type !== 'person' && (
              <AccountSubContainer>
                <Account>Razão Social</Account>
                <Account>{paymentData.name}</Account>
              </AccountSubContainer>
            )}
            <AccountSubContainer>
              <Account>
                {paymentData.account_type &&
                paymentData.account_type === 'person'
                  ? 'CPF'
                  : 'CNPJ'}
              </Account>
              <Account>{docFormatted}</Account>
            </AccountSubContainer>
            <Account>
              <a onClick={() => setIsVisible(true)}>Ver perfil</a>
            </Account>
          </AccountContainer>

          {paymentData.type === 'simple' && (
            <>
              <AccountSubContainer>
                <Title>Descrição</Title>
                <Description>{paymentData.description}</Description>
              </AccountSubContainer>
              <TotalContainer>
                <Title className="total">Total a pagar</Title>
                <Total>
                  <Currency
                    quantity={paymentData.amountTotal / 100}
                    decimal=","
                    currency="BRL"
                  />
                </Total>
              </TotalContainer>
            </>
          )}

          {paymentData.type === 'advanced' && (
            <>
              <Title className="advanced">Descrição</Title>

              {items.length > 0 &&
                items.map(item => (
                  <ItemContainer key={item.id}>
                    <Item>
                      <p>{`${item.quantity}x ${item.item}`}</p>
                      <p>
                        <Currency
                          quantity={(item.quantity * item.amount) / 100}
                          decimal=","
                          currency="BRL"
                        />
                      </p>
                    </Item>
                    <ItemPrice>
                      <Currency
                        quantity={item.amount / 100}
                        decimal=","
                        currency="BRL"
                      />
                      <span>por unid.</span>
                    </ItemPrice>
                  </ItemContainer>
                ))}

              {paymentData.subtotal && (
                <div>
                  <Info>
                    <p>Subtotal</p>
                    <p>
                      <Currency
                        quantity={paymentData.subtotal}
                        decimal=","
                        currency="BRL"
                      />
                    </p>
                  </Info>
                  {!!paymentData.discount && (
                    <Info>
                      <p>Descontos</p>
                      <p>
                        <span>-</span>
                        <Currency
                          quantity={paymentData.discount}
                          decimal=","
                          currency="BRL"
                        />
                      </p>
                    </Info>
                  )}
                  {!!paymentData.shipping_amount && (
                    <Info>
                      <p>Frete</p>
                      <p>
                        <Currency
                          quantity={paymentData.shipping_amount}
                          decimal=","
                          currency="BRL"
                        />
                      </p>
                    </Info>
                  )}

                  <TotalContainer>
                    <Title className="total">Total a pagar</Title>
                    <Total>
                      <Currency
                        quantity={paymentData.total}
                        decimal=","
                        currency="BRL"
                      />
                    </Total>
                  </TotalContainer>
                </div>
              )}
            </>
          )}
        </Details>
      </Box>

      <Security>
        <Icon
          kind="bold"
          group="interface-essential"
          category="lock-unlock"
          file="lock-1.svg"
          size="13"
          color="#494949"
        />
        <p>Pagamento seguro com</p>
        <img
          className="logo"
          src="https://bifrost.alboompro.com/products/alboompay/AlboomPay2022-full.svg"
          alt="AlboomPay"
        />
      </Security>
    </Container>
  );
}

Sidebar.propTypes = {
  fixed: PropTypes.bool.isRequired,
};
