import styled from 'styled-components';

export const Container = styled.main`
  width: 100%;
  max-width: 1110px;
  margin-top: 50px;
  border: 1px solid #e8e8e8;
  border-radius: 5px;

  header {
    width: 100%;
    background-color: #fafafa;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 110px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    @media (max-width: 768px) {
      padding: 20px;
    }

    img {
      margin-right: 20px;
    }

    h1 {
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px;
      letter-spacing: 0em;
      text-align: left;
    }
  }

  article {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    padding: 32px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    p {
      max-width: 490px;
      text-align: center;

      & + p {
        margin-top: 17px;
      }

      span {
        font-weight: 500;
      }
    }
  }

  a.printInvoice {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #44adf8;
    color: #ffffff;
    border: none;
    border-radius: 3px;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    height: 38px;
    padding: 0 24px;
    cursor: pointer;
    text-decoration: none;
  }
`;

export const Alert = styled.section`
  margin-top: 47px;
  width: 100%;
  max-width: 665px;
  background: #eef8ff;
  border-radius: 3px;
  padding: 30px;

  h1 {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0em;
    margin: 0;
    padding: 0;
  }

  ul {
    margin-top: 26px;
    padding-left: 15px;

    li {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 14px;
      letter-spacing: 0em;
      text-align: left;

      & + li {
        margin-top: 20px;
      }
    }
  }
`;

export const CodeInput = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  width: 100%;
  height: 50px;
  position: relative;
  padding: 0 125px 0 25px;

  background: #ffffff;
  border: ${props =>
    props.active ? '1px solid transparent' : '1px solid #aeaeae'};
  border-radius: 3px;

  box-shadow: ${props => (props.active ? '0 0 2px 1px #44adf8;' : 'none;')};

  .copy {
    position: absolute;
    top: 7px;
    right: 7px;

    width: 100px;
    height: 35px;

    background: #44adf8;
    border-radius: 3px;

    span {
      transition: transform 0.3s;
      transition-delay: ${props => (props.active ? '0' : '0.3s')};
      transform: ${props => (props.active ? 'scale(0)' : 'scale(1.0)')};
    }

    img {
      transition: transform 0.3s;
      transition-delay: ${props => (props.active ? '0.3s' : '0s')};
      transform: ${props => (props.active ? 'scale(1.0)' : 'scale(0)')};
      position: absolute;
      right: calc(50% - 10px);
    }

    div {
      color: #ffffff !important;
    }
  }
`;

export const Footer = styled.footer`
  max-width: 658px;
  margin: 0 auto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  color: #656565;
  margin: 30px 0;

  p {
    margin: 0;
    padding: 0;

    & + p {
      margin-top: 18px;
    }

    span {
      color: #383838;

      em {
        font-weight: 500;
        font-style: normal;
      }
    }
  }
`;
