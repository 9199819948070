import React from 'react';

import PropTypes from 'prop-types';

const Pix = ({ color, height, width }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 22 18"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.1615 13.8936C13.811 13.8945 13.4638 13.8259 13.14 13.6919C12.8162 13.5578 12.5222 13.3609 12.275 13.1125L9.55105 10.3886C9.45472 10.2965 9.32658 10.2451 9.1933 10.2451C9.06003 10.2451 8.93189 10.2965 8.83556 10.3886L6.10187 13.1212C5.8546 13.3695 5.56058 13.5664 5.23678 13.7005C4.91298 13.8345 4.56583 13.9031 4.21537 13.9022H3.68048L7.13139 17.3531C7.649 17.87 8.35056 18.1602 9.08201 18.1602C9.81346 18.1602 10.515 17.87 11.0326 17.3531L14.4922 13.8936H14.1615Z"
      fill={color}
    />

    <path
      d="M4.21459 4.25858C4.56506 4.25757 4.91225 4.32608 5.23607 4.46014C5.55988 4.59421 5.8539 4.79117 6.10108 5.03963L8.83477 7.7716C8.92976 7.86629 9.0584 7.91946 9.19252 7.91946C9.32663 7.91946 9.45528 7.86629 9.55026 7.7716L12.2742 5.04768C12.5214 4.79923 12.8154 4.60226 13.1392 4.4682C13.463 4.33413 13.8102 4.26562 14.1607 4.26663H14.4885L11.029 0.807096C10.5114 0.290279 9.8098 0 9.07835 0C8.3469 0 7.64534 0.290279 7.12773 0.807096L3.67682 4.258L4.21459 4.25858Z"
      fill={color}
    />

    <path
      d="M17.3505 7.13047L15.2598 5.03979C15.2127 5.05937 15.1623 5.06972 15.1114 5.07027H14.1612C13.6655 5.07076 13.19 5.26716 12.8384 5.61667L10.1151 8.34058C9.86972 8.58556 9.53719 8.72315 9.1905 8.72315C8.8438 8.72315 8.51127 8.58556 8.26594 8.34058L5.53225 5.60689C5.18141 5.25792 4.70711 5.06138 4.21228 5.05992H3.0476C2.99949 5.05912 2.95194 5.04957 2.90726 5.03174L0.807383 7.13047C0.29039 7.6481 0 8.34978 0 9.08138C0 9.81297 0.29039 10.5146 0.807383 11.0323L2.90611 13.131C2.95074 13.1129 2.9983 13.1032 3.04645 13.1023H4.21515C4.70992 13.101 5.18423 12.9047 5.53512 12.5559L8.26882 9.82217C8.76287 9.32869 9.62387 9.32812 10.1179 9.82217L12.8413 12.5461C13.1926 12.896 13.6682 13.0927 14.1641 13.0931H15.1143C15.1654 13.0935 15.2159 13.1036 15.2632 13.123L17.3533 11.0329C17.8703 10.5152 18.1607 9.81355 18.1607 9.08195C18.1607 8.35036 17.8703 7.64867 17.3533 7.13104L17.3505 7.13047Z"
      fill={color}
    />
  </svg>
);

Pix.propTypes = {
  color: PropTypes.string.isRequired,

  height: PropTypes.number.isRequired,

  width: PropTypes.number.isRequired,
};

export default Pix;

