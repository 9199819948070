import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';
import { useField } from '@rocketseat/unform';

export default function Mask({ name, inputMask, ...options }) {
  const ref = useRef(null);
  const { fieldName, registerField, defaultValue = '', error } = useField(name);
  const [mask, setMask] = useState(defaultValue);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: ref.current,
      path: 'props.value',
      clearValue: pickerRef => {
        pickerRef.setInputValue(null);
      },
    });
  }, [fieldName, registerField]);

  function handleMask(e) {
    const { value } = e.target;
    return setMask(value);
  }

  return (
    <>
      <MaskedInput
        name={fieldName}
        mask={inputMask}
        value={mask}
        onChange={e => handleMask(e)}
        ref={ref}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...options}
      />
      {error && <span>{error}</span>}
    </>
  );
}

Mask.propTypes = {
  name: PropTypes.string.isRequired,
  inputMask: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
    .isRequired,
};
