/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import ProfileTab from '../ProfileTab';
import Avatar from '../Avatar';
import {
  Wrapper,
  Container,
  Logo,
  UserContainer,
  User,
  Name,
  Text,
  DoNotHaveWebsite,
  Website,
  SeeProfile,
} from './styles';

function Header({ isVisible, setIsVisible }) {
  const [data, setData] = useState({});
  const { account } = useSelector(state => state.auth.orders);

  useEffect(() => {
    if (account) {
      setData({
        name: account.fullname,
        display_name: account.display_name,
        website: account.website,
        haveWebsite: account.have_website,
        type: account.type,
      });
    }
  }, [account]);

  return (
    <Wrapper>
      <Container>
        <Logo
          src="https://bifrost.alboompro.com/products/alboompay/AlboomPay2022-full.svg"
          alt="Logo Alboom Pay"
        />
        {Object.keys(data).length !== 0 && (
          <UserContainer>
            <Avatar />
            <User>
              <Name>
                {data.type && data.type === 'person'
                  ? data.name
                  : data.display_name}
              </Name>
              {typeof data.haveWebsite &&
                typeof data.website === 'undefined' && (
                  <Text onClick={() => setIsVisible(true)}>Ver Perfil</Text>
                )}
              {data.haveWebsite && data.website !== '' && (
                <>
                  <Website
                    className="website"
                    href={data.website}
                    target="_blank"
                  >
                    {data.website.replace('https://', '')}
                  </Website>
                  <SeeProfile onClick={() => setIsVisible(true)}>
                    Ver Perfil
                  </SeeProfile>
                </>
              )}
              {data.haveWebsite === false && (
                <>
                  <DoNotHaveWebsite className="website">
                    Não possui website
                  </DoNotHaveWebsite>
                  <SeeProfile onClick={() => setIsVisible(true)}>
                    Ver Perfil
                  </SeeProfile>
                </>
              )}
            </User>
          </UserContainer>
        )}
      </Container>
      <ProfileTab isVisible={isVisible} setIsVisible={setIsVisible} />
    </Wrapper>
  );
}

export default Header;
