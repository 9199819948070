import styled from 'styled-components';

export const Container = styled.div`
  height: calc(100vh - 90px);
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 50px 0 0;
`;

export const Wrapper = styled.div`
  max-width: 1100px;
  width: 100%;
  height: auto;
  padding: 0 15px;
`;

export const Content = styled.div`
  padding: 40px 0 80px;
  text-align: center;
  font-size: 15px;
  color: #3f3f3f;
  max-width: 420px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    margin-bottom: 15px;
  }
`;
