import styled from 'styled-components';
import media from '../../styles/media';
import colors from '../../styles/colors';

export const Container = styled.div`
  color: ${colors.darkgray};
  text-align: center;
  padding: 3em 5em;
  width: 1100px;
  max-width: 100%;

  @media ${media.small} {
    padding: 1em;
  }
`;

export const Body = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.moregray};
`;

export const Content = styled.div`
  padding: 30px 15px;

  p {
    color: ${colors.darkgray};
    font-size: 0.9em;
    line-height: 1.5em;
    max-width: 500px;
    width: 100%;
    margin: 0 auto 5px;

    span {
      font-weight: 500;
    }
  }
`;
