import styled from 'styled-components';
import colors from '../../../styles/colors';

export const Body = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.moregray};
`;

export const Container = styled.div`
  color: ${colors.darkgray};
  text-align: center;
  width: 100%;
  max-width: 1100px;
  margin: 4em 0;
  padding: 15px;
`;

export const Content = styled.div`
  margin-bottom: 2em;
  padding: 0 210px;

  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 1024px) {
    padding: 0 100px;
  }

  @media screen and (max-width: 930px) {
    padding: 0 50px;
  }

  @media screen and (max-width: 570px) {
    padding: 0 10px;
  }
`;

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 30px 0;

  p {
    font-size: 15px;
    color: #3f3f3f;
    margin-bottom: 15px;
    max-width: 460px;

    span {
      font-weight: 500;
    }
  }
`;

export const CodeInput = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  width: 100%;
  height: 50px;
  position: relative;
  padding: 0 125px 0 25px;

  background: #ffffff;
  border: ${props =>
    props.active ? '1px solid transparent' : '1px solid #aeaeae'};
  border-radius: 3px;

  box-shadow: ${props => (props.active ? '0 0 2px 1px #44adf8;' : 'none;')};

  .copy {
    position: absolute;
    top: 7px;
    right: 7px;

    width: 100px;
    height: 35px;

    background: #44adf8;
    border-radius: 3px;

    span {
      transition: transform 0.3s;
      transition-delay: ${props => (props.active ? '0' : '0.3s')};
      transform: ${props => (props.active ? 'scale(0)' : 'scale(1.0)')};
    }

    img {
      transition: transform 0.3s;
      transition-delay: ${props => (props.active ? '0.3s' : '0s')};
      transform: ${props => (props.active ? 'scale(1.0)' : 'scale(0)')};
      position: absolute;
      right: calc(50% - 10px);
    }

    div {
      color: #ffffff !important;
    }
  }
`;

export const LinkButton = styled.a`
  background-color: #50aff4;
  margin: 40px 0 50px;
  height: 38px;
  width: 185px;
  padding: 0 30px;
  border-radius: 2px;
  cursor: pointer;
  transition: opacity 150ms ease-in-out;
  color: #fff;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;

  &:hover {
    opacity: 0.8;
  }
`;

export const Information = styled.div`
  background: #eef8ff;
  padding: 25px 35px 30px;
  font-size: 14px;
  border-radius: 3px;
  margin-bottom: 30px;
`;

export const Title = styled.p`
  font-weight: 500;
  font-size: 14px;
  color: #000000;
  text-align: left;
  margin-bottom: 30px;

  &.infoPayment {
    font-weight: 500;
    font-size: 18px;
    color: #3f3f3f;
    text-align: center;
    max-width: 460px;
    margin: 0 auto 30px;
  }
`;

export const List = styled.ul`
  margin-left: 15px;
  text-align: left;
  list-style-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='7' height='7' viewBox='-1 -1 2 2'><circle r='1' /></svg>");

  li {
    font-size: 14px;
    color: #3f3f3f;
    margin-bottom: 20px;
    padding-left: 15px;

    &:last-child {
      margin-bottom: 0px;
    }
  }
`;

export const InfoPayment = styled.div`
  background: #f8f8f8;
  border-radius: 3px;
  padding: 30px;
  width: 100%;

  .wrapperBlockQuote {
    position: relative;
    max-width: 320px;
    width: 100%;
    margin: 0 auto;

    &::before {
      content: '“';
      display: block;
      font-size: 46px;
      color: #858585;
      position: absolute;
      left: 0;
      top: -20px;
      font-family: 'Poppins', sans-serif !important;
    }

    &::after {
      content: '“';
      font-size: 46px;
      color: #858585;
      position: absolute;
      right: 0;
      bottom: 15px;
      transform: rotate(180deg);
      font-family: 'Poppins', sans-serif !important;
    }
  }

  > div > p {
    text-align: center;
    font-size: 16px;
    color: #000000;
    margin-bottom: 13px;
  }

  .payment-date {
    width: 200px;
    padding: 7px 16px;
    margin-bottom: 45px;
    cursor: pointer;
  }
`;

export const Footer = styled.div`
  font-size: 14px;
  color: #656565;
  max-width: 650px;
  margin: 15px auto;

  .secure {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      margin-right: 4px;
      width: 100px;
    }
  }
`;

export const Sended = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 17px;
  color: #323232;

  > img {
    margin-right: 10px;
  }
`;
