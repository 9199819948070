/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import Icon from '@alboompro/boom-components/general/icon';
import Button from '@alboompro/boom-components/general/button';
import Clipboard from 'react-clipboard.js';
import moment from 'moment';

import { Container, CodeInput, Alert, Footer } from './styles';
import PixSuccess from '../../components/PixForm/Receipt';
import PixExpired from '../PixExpired';

const WaitingPayment = ({ order }) => {
  const [active, setActive] = useState(false);
  const {
    amount: value,
    boleto_barcode: boletoBarCode,
    boleto_expiration_date: boletoExpirationDate,
    boleto_url: boletoURL,
    customer: { name, email },
    payment_method,
    pix_qr_code,
    date_updated,
    pix_expiration_date,
  } = order.details.paylink;
  const amount = (value / 100).toFixed(2).replace('.', ',');

  const formatDate = d => {
    return moment(d).format('DD/MM/YYYY');
  };

  function handleCopy() {
    setActive(true);
    setTimeout(() => {
      setActive(false);
    }, 3000);
  }

  return payment_method === 'boleto' ? (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        maxWidth: '1100px',
      }}
    >
      <Container>
        <header>
          <Icon
            kind="bold"
            group="interface-essential"
            category="time"
            file="time-clock-circle-alternate.svg"
            size="30"
            color="#44ADF8"
          />
          <h1>Um boleto já foi gerado para este pagamento</h1>
        </header>
        <article>
          <p>
            O boleto bancário no valor de <span>R$ {amount}</span> referente ao
            pagamento para <span>{name}</span> foi gerado
          </p>
          <p>
            Pague agora mesmo com a numeração do boleto bancário via internet
            banking ou imprima o boleto para pagamento.
          </p>

          <Clipboard
            style={{
              width: '100%',
              border: 'none',
              maxWidth: '665px',
              marginTop: '50px',
              marginBottom: '42px',
            }}
            data-clipboard-text={boletoBarCode || ''}
            onSuccess={handleCopy}
          >
            <CodeInput active={active}>
              {boletoBarCode || ''}
              <Button className="copy">
                <Icon
                  kind="bold"
                  group="interface-essential"
                  category="form-validation"
                  file="check-circle-1-alternate.svg"
                  size="20"
                  color="#fff"
                />
                <span>Copiar</span>
              </Button>
            </CodeInput>
          </Clipboard>

          <a href={boletoURL} target="_blank" className="printInvoice">
            Imprimir boleto
          </a>
          <Alert>
            <h1>Informações importantes</h1>
            <ul>
              <li>
                A data de vencimento do boleto é{' '}
                {formatDate(boletoExpirationDate)}.
              </li>
              <li>
                O pagamento é confirmado em até 3 dias úteis após efetuado o
                pagamento do boleto.
              </li>
              <li>
                Um comprovante de pagamento será enviado para {email} após a
                confirmação de pagamento.
              </li>
            </ul>
          </Alert>
        </article>
      </Container>
      <Footer>
        <p>
          O Alboom Pay é 100% seguro. Processamos o pagamento através do
          Pagar.me, empresa do grupo Stone e líder em pagamentos online no
          Brasil.{' '}
        </p>
        <p>
          <span>
            <em>Alboom</em> Pay
          </span>{' '}
          | Pagamentos online 100% seguro
        </p>
      </Footer>
    </div>
  ) : new Date(pix_expiration_date) > new Date() ? (
    <PixSuccess
      pixData={{
        payment_id: order.details._id,
        pix_qr_code,
        date_updated,
      }}
    />
  ) : (
    <PixExpired name={order.account.fullname} />
  );
};

export default WaitingPayment;
