import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Scope } from '@rocketseat/unform';
import { toast } from 'react-toastify';
import { setPayer } from '../../store/modules/payer/actions';
import InputMask from '../InputMask';
import fetchCEPData from '../../services/viacep';
import masks from '../../utils/masks';
import { RowWrapper, StyledInput, Col, Label } from '../../styles/form';

export default function Address({ namePrefix }) {
  const dispatch = useDispatch();
  const payer = useSelector(state => state.payer);

  async function handleCep(e, addr) {
    const c = e.target.value;

    dispatch(setPayer({ [`${addr}_zipcode`]: c }));

    const onlyNums = c.replace(/\D/g, '');

    if (onlyNums.length === 8) {
      const response = await fetchCEPData(c);
      const { localidade, uf, logradouro, bairro, cep, erro } = response.data;

      if (erro) return toast.error('CEP Inválido');

      return dispatch(
        setPayer({
          [`${addr}_street`]: logradouro,
          [`${addr}_city`]: localidade,
          [`${addr}_state`]: uf,
          [`${addr}_neighborhood`]: bairro,
          [`${addr}_zipcode`]: cep,
        })
      );
    }

    return false;
  }

  return (
    <>
      {namePrefix === 'billing' ? (
        <Scope path={`${namePrefix}_address`}>
          <RowWrapper>
            <Col width="30%" mr="2%">
              <Label>País</Label>
              <StyledInput
                value="Brasil"
                name={`${namePrefix}_country`}
                disabled
                readOnly
              />
              <StyledInput
                value="br"
                name={`${namePrefix}_country_iso`}
                hidden
                readOnly
                disabled
              />
            </Col>

            <Col width="68%">
              <Label>CEP</Label>
              <InputMask
                inputMask={masks.cep}
                name={`${namePrefix}_zipcode`}
                value={payer.billing_zipcode}
                placeholder=""
                className="mask"
                onChange={e => handleCep(e, 'billing')}
              />
            </Col>
          </RowWrapper>

          <RowWrapper>
            <Col width="80%" mr="2%">
              <Label>Rua</Label>
              <StyledInput
                name={`${namePrefix}_street`}
                value={payer.billing_street}
                onChange={e =>
                  dispatch(setPayer({ billing_street: e.target.value }))
                }
              />
            </Col>
            <Col width="18%">
              <Label>Número</Label>
              <StyledInput
                name={`${namePrefix}_street_number`}
                value={payer.billing_street_number}
                placeholder=""
                onChange={e =>
                  dispatch(setPayer({ billing_street_number: e.target.value }))
                }
              />
            </Col>
          </RowWrapper>

          <RowWrapper>
            <Col width="49%" mr="2%">
              <Label>Bairro</Label>
              <StyledInput
                name={`${namePrefix}_neighborhood`}
                value={payer.billing_neighborhood}
                required
                onChange={e =>
                  dispatch(setPayer({ billing_neighborhood: e.target.value }))
                }
              />
            </Col>

            <Col width="49%">
              <Label>Complemento</Label>
              <StyledInput
                name={`${namePrefix}_complement`}
                value={payer.billing_complement}
                onChange={e =>
                  dispatch(setPayer({ billing_complement: e.target.value }))
                }
              />
            </Col>
          </RowWrapper>

          <RowWrapper>
            <Col width="49%" mr="2%">
              <Label>Cidade</Label>
              <StyledInput
                name={`${namePrefix}_city`}
                value={payer.billing_city}
                onChange={e =>
                  dispatch(setPayer({ billing_city: e.target.value }))
                }
                required
              />
            </Col>

            <Col width="49%">
              <Label>Estado</Label>
              <StyledInput
                name={`${namePrefix}_state`}
                value={payer.billing_state}
                onChange={e =>
                  dispatch(setPayer({ billing_state: e.target.value }))
                }
                required
              />
            </Col>
          </RowWrapper>
        </Scope>
      ) : (
        <Scope path={`${namePrefix}_address`}>
          <RowWrapper>
            <Col width="30%" mr="2%">
              <Label>País</Label>

              <StyledInput
                value="Brasil"
                name={`${namePrefix}_country`}
                disabled
                readOnly
              />

              <StyledInput
                value="br"
                name={`${namePrefix}_country_iso`}
                hidden
                readOnly
                disabled
              />
            </Col>

            <Col width="68%">
              <Label>CEP</Label>

              <InputMask
                inputMask={masks.cep}
                name={`${namePrefix}_zipcode`}
                value={payer.shipping_zipcode}
                placeholder=""
                className="mask"
                onChange={e => handleCep(e, 'shipping')}
              />
            </Col>
          </RowWrapper>

          <RowWrapper>
            <Col width="80%" mr="2%">
              <Label>Rua</Label>

              <StyledInput
                name={`${namePrefix}_street`}
                value={payer.shipping_street}
                // onChange={e => setStreet(e.target.value)}

                required
              />
            </Col>

            <Col width="18%">
              <Label>Número</Label>

              <StyledInput
                name={`${namePrefix}_street_number`}
                value={payer.shipping_street_number}
                placeholder=""
                required
                onChange={e => {
                  dispatch(
                    setPayer({
                      [`${namePrefix}_street_number`]: e.target.value,
                    })
                  );
                }}
              />
            </Col>
          </RowWrapper>

          <RowWrapper>
            <Col width="49%" mr="2%">
              <Label>Bairro</Label>

              <StyledInput
                name={`${namePrefix}_neighborhood`}
                value={payer.shipping_neighborhood}
                // onChange={e => setNeighborhood(e.target.value)}

                required
              />
            </Col>

            <Col width="49%">
              <Label>Complemento</Label>

              <StyledInput
                name={`${namePrefix}_complement`}
                value={payer.shipping_complement}
                onChange={e =>
                  dispatch(setPayer({ shipping_complement: e.target.value }))
                }
                placeholder=""
              />
            </Col>
          </RowWrapper>

          <RowWrapper>
            <Col width="49%" mr="2%">
              <Label>Cidade</Label>

              <StyledInput
                name={`${namePrefix}_city`}
                value={payer.shipping_city}
                // onChange={e => setCity(e.target.value)}

                required
              />
            </Col>

            <Col width="49%">
              <Label>Estado</Label>

              <StyledInput
                name={`${namePrefix}_state`}
                value={payer.shipping_state}
                // onChange={e => setStateAbbr(e.target.value)}

                required
              />
            </Col>
          </RowWrapper>
        </Scope>
      )}
    </>
  );
}

Address.propTypes = {
  namePrefix: PropTypes.string,
};

Address.defaultProps = {
  namePrefix: '_',
};
