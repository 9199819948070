/* eslint-disable no-empty */
/* eslint-disable camelcase */

import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Button from '@alboompro/boom-components/general/button';

import RadioInput from '../RadioInput';
import CheckBox from '../CheckBox';
import { schema } from '../../validators/schemas';
import masks, { maskPhone, maskCPForCNPJ } from '../../utils/masks';
import Address from '../Address';

import { setPayer } from '../../store/modules/payer/actions';
import {
  paymentRequest,
  paymentIsIframe,
} from '../../store/modules/payment/actions';
import {
  Container,
  StyledForm,
  StyledInput,
  Label,
  RowWrapper,
  Title,
  Col,
  RowCenter,
  RowLeft,
  UserTermsText,
} from '../../styles/form';

export default function Form() {
  const payer = useSelector(state => state.payer);
  const loading = useSelector(state => state.payment.loading);
  const order = useSelector(state => state.auth.orders);
  const { search } = useLocation();
  const dispatch = useDispatch();
  const [terms, setTerms] = useState(false);

  useEffect(() => {
    if (order) {
      const { payers } = order;

      if (payers) {
        dispatch(
          setPayer({
            fullname: payers.name,
            email: payers.email,
            ref_id: payers.ref_id,
          })
        );
      }
    }
  }, [order, dispatch]);

  async function handleSubmit(data) {
    data.payment_method = 'boleto';
    dispatch(paymentRequest({ paymentForm: data, ...order }));
  }

  useEffect(() => {
    const searchParams = new URLSearchParams(search);
    const queryParam = searchParams.get('iframe');
    if (queryParam && window.parent) dispatch(paymentIsIframe(true));
  }, []);

  return (
    <Container>
      <>
        <StyledForm schema={schema} onSubmit={handleSubmit}>
          <RowWrapper>
            <Title>Seus dados</Title>
          </RowWrapper>

          <RowWrapper>
            <Label>Nome Completo ou Razão Social</Label>
            <StyledInput
              name="name"
              placeholder=""
              value={payer.fullname}
              onChange={e => dispatch(setPayer({ fullname: e.target.value }))}
            />
          </RowWrapper>

          <RowWrapper>
            <Label>Email</Label>
            <StyledInput
              name="email"
              placeholder=""
              value={payer.email}
              onChange={e => dispatch(setPayer({ email: e.target.value }))}
            />
          </RowWrapper>

          <RowWrapper>
            <Label>Telefone</Label>
            <StyledInput
              type="text"
              inputMask={masks.phone}
              name="phone_number"
              placeholder="( ) ____-____"
              className="mask"
              value={payer.phone}
              onChange={e =>
                dispatch(setPayer({ phone: maskPhone(e.target.value) }))
              }
            />
          </RowWrapper>

          <RowWrapper>
            <Label>CPF/CNPJ</Label>
            <StyledInput
              inputMask={masks.cpf}
              name="cpf"
              placeholder=""
              className="mask"
              value={payer.cpf}
              onChange={e =>
                dispatch(setPayer({ cpf: maskCPForCNPJ(e.target.value) }))
              }
            />
          </RowWrapper>

          <RowWrapper>
            <Title>Endereço de faturamento</Title>
          </RowWrapper>

          <Address namePrefix="billing" />

          {!!order.shipping_enabled && (
            <RowWrapper>
              <Title>endereço de entrega</Title>
              <RowWrapper>
                <Col width="60%">
                  <RadioInput
                    name="same_delivery_address"
                    type="radio"
                    value="same"
                    label="  "
                    checked={payer.shipping === 'same'}
                    onChange={e =>
                      dispatch(setPayer({ shipping: e.target.value }))
                    }
                  >
                    <p>Usar o mesmo endereço de faturamento</p>
                  </RadioInput>
                </Col>

                <Col width="35%">
                  <RadioInput
                    name="other_delivery_address"
                    type="radio"
                    value="other"
                    checked={payer.shipping === 'other'}
                    label="  "
                    onChange={e =>
                      dispatch(setPayer({ shipping: e.target.value }))
                    }
                  >
                    <p>Usar outro endereço</p>
                  </RadioInput>
                </Col>
              </RowWrapper>

              {payer.shipping === 'other' && <Address namePrefix="shipping" />}
            </RowWrapper>
          )}

          <RowLeft>
            <CheckBox
              name="terms"
              label="  "
              required
              onChange={() => setTerms(!terms)}
            >
              <UserTermsText>
                Li e aceito os{' '}
                <a
                  href="https://www.alboompro.com/terms-of-use/"
                  target="blank"
                >
                  termos de uso
                </a>.
              </UserTermsText>
            </CheckBox>
          </RowLeft>

          <RowCenter>
            <Button
              type="primary"
              htmlType="submit"
              style={{
                backgroundColor: '#44ADF8',
                width: 350,
                height: 60,
                fontSize: 17,
              }}
              loading={loading}
              disabled={loading}
            >
              Confirmar o pagamento
            </Button>
          </RowCenter>
        </StyledForm>
      </>
    </Container>
  );
}
