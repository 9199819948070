/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Sidebar from '../../components/SideBar';
import Form from './PaymentOption';
import Loading from '../../components/Loading';
import LinkPaid from '../../components/LinkPaid';
import WaitingPayment from '../../components/WaitingPayment';
import { signInRequest } from '../../store/modules/auth/actions';
import { throttle } from '../../utils/helpers';

import { Container, Content, Wrapper } from './styles';

export default function Payment({ setIsVisible }) {
  const dispatch = useDispatch();
  const loading = useSelector(state => state.auth.loading);
  const { message } = useSelector(state => state.auth.errors);
  const order = useSelector(state => state.auth.orders);
  const { username, orderID } = useParams();
  const location = useLocation();
  const [fixed, setFixed] = useState(false);
  const ref = useRef(null);

  const isPaymentButton = location.pathname.includes('/button/');

  useEffect(() => {
    async function getUserData() {
      dispatch(signInRequest({ username, orderID, isPaymentButton }));
    }
    getUserData();
  }, [dispatch, orderID, username]);

  const checkPosition = () => {
    const elementRects = ref.current && ref.current.getClientRects()[0];
    const offSetTop = -56;

    return elementRects && elementRects.top <= offSetTop
      ? setFixed(true)
      : setFixed(false);
  };

  useEffect(
    () => window.addEventListener('scroll', throttle(checkPosition, 100)),
    []
  );

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <Container>
          <Content>
            {message === 'link_paid' ? (
              <LinkPaid />
            ) : order.waiting_payment ? (
              <WaitingPayment order={order} />
            ) : (
              <Wrapper ref={ref}>
                <Sidebar fixed={fixed} setIsVisible={setIsVisible} />
                <Form fixed={fixed} />
              </Wrapper>
            )}
          </Content>
        </Container>
      )}
    </>
  );
}

