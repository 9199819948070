import styled from 'styled-components';
import colors from '../../styles/colors';

export const Label = styled.label`
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 12px;
  cursor: pointer;
  user-select: none;

  span.label {
    display: flex;
    align-items: center;
    position: relative;

    p {
      color: ${colors.darkgray};
      font-size: 1em;

      span {
        font-size: 0.8em;
      }

      img {
        display: block;
        width: 220px;
        padding: 5px 0;
      }
    }

    svg {
      margin-left: 0.6rem;
      fill: #242424;

      &:hover ~ div {
        display: block;
      }
    }
  }

  &.container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    margin-right: 1rem;
    height: 14px;
    width: 14px;
    border-radius: 50%;
    border: 1px solid #d8d8d8;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #efedef;
  }

  input:checked ~ .checkmark {
  }

  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the indicator (dot/circle) */
  .checkmark:after {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: ${colors.lightblue};
  }

  > input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
`;
export const Message = styled.div`
  display: none;
  position: absolute;
  background: #fff;
  color: #9c9a9a;
  font-size: 1.2rem;
  min-width: 20rem;
  left: 2.5rem;
  top: 1rem;
  padding: 1rem;
  border-radius: 3px;
  box-shadow: 0 0 7px 0 rgba(6, 6, 6, 0.08);
`;
