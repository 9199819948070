/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import Currency from 'react-currency-formatter';
import moment from 'moment';

import Icon from '@alboompro/boom-components/general/icon';
import Button from '@alboompro/boom-components/general/button';
import Clipboard from 'react-clipboard.js';
import { invoiceNotify } from '../../../store/modules/payment/actions';
import history from '../../../services/history';

import Load from '../../Loading';
import Select from '../../Select';
import Box from '../../Box';

import {
  Body,
  Container,
  Content,
  Description,
  CodeInput,
  LinkButton,
  Information,
  Title,
  List,
  InfoPayment,
  Sended,
  Footer,
} from './styles';

export default function Receipt() {
  const dispatch = useDispatch();
  const [options, setOptions] = useState([]);
  const [date, setDate] = useState(null);
  const [active, setActive] = useState(false);

  const {
    loading,
    receipt: { transaction: paymentData },
    notification,
  } = useSelector(state => state.payment);

  const expirationDays = useSelector(state =>
    state.auth && state.auth.orders && state.auth.orders.id
      ? state.auth.orders.account.payments_settings.invoice.expiration_in_days
      : history.push('/')
  );

  const { account } = useSelector(state =>
    state.auth && state.auth.orders && state.auth.orders.id
      ? state.auth.orders
      : history.push('/')
  );

  const { id: order_id } = useSelector(state =>
    state.auth && state.auth.orders && state.auth.orders.id
      ? state.auth.orders
      : { id: false }
  );

  function addBusinessDays(days) {
    var d = moment(new Date()).add(Math.floor(days / 5) * 7, 'd');
    var remaining = days % 5;
    while (remaining) {
      d.add(1, 'd');
      if (d.day() !== 0 && d.day() !== 6) remaining--;
    }
    return d;
  }

  function buildNextValidDate(number) {
    return {
      id: addBusinessDays(number).format('YYYY-MM-DD'),
      title: addBusinessDays(number).format('dddd DD/MM'),
    };
  }

  function formatDate(d) {
    return moment(d).format('DD/MM/YYYY');
  }

  function Notify() {
    if (!date) return toast.error('Informe uma data para notificação.');
    dispatch(invoiceNotify({ date, order_id }));
  }

  function handleCopy() {
    setActive(true);
    setTimeout(() => {
      setActive(false);
    }, 3000);
  }

  useEffect(() => {
    if (expirationDays) {
      const arr = [];
      for (let i = 0; i < expirationDays; i += 1) {
        arr.push(buildNextValidDate(i + 1));
      }
      setOptions(arr);
    }
  }, [expirationDays]);

  useEffect(() => {
    if (!paymentData) {
      history.push('/');
    }
  }, [paymentData]);

  return (
    <Body>
      {!paymentData ? (
        <Load />
      ) : (
        <Container id="no-print">
          <Box
            title="Agora só falta você pagar!"
            icon={
              <Icon
                kind="bold"
                group="interface-essential"
                category="time"
                file="time-clock-circle-alternate.svg"
                size="32"
                color="#44ADF8"
              />
            }
          >
            <Content>
              <Description>
                {paymentData.customer && (
                  <p>
                    O boleto bancário no valor de{' '}
                    <span>
                      <Currency
                        quantity={paymentData.amount / 100}
                        decimal=","
                        currency="BRL"
                      />
                    </span>{' '}
                    referente ao pagamento para{' '}
                    <span>{paymentData.customer.name}</span> foi gerado.
                  </p>
                )}

                <p>
                  Pague agora mesmo com a numeração do boleto bancário via
                  internet banking ou imprima o boleto para pagamento.
                </p>
              </Description>
              <Clipboard
                style={{ width: '100%', border: 'none' }}
                data-clipboard-text={paymentData.boleto_barcode || ''}
                onSuccess={handleCopy}
              >
                <CodeInput active={active}>
                  {paymentData.boleto_barcode || ''}
                  <Button className="copy">
                    <Icon
                      kind="bold"
                      group="interface-essential"
                      category="form-validation"
                      file="check-circle-1-alternate.svg"
                      size="20"
                      color="#fff"
                    />
                    <span>Copiar</span>
                  </Button>
                </CodeInput>
              </Clipboard>

              <LinkButton
                href={paymentData.boleto_url}
                target="_blank"
                className="print"
                type="primary"
                style={{
                  backgroundColor: '#50AFF4',
                  margin: '40px 0 50px',
                  height: 38,
                  width: 185,
                }}
              >
                Imprimir boleto
              </LinkButton>

              <Information>
                <Title>Informações importantes</Title>
                <List>
                  <li>
                    {`A data de vencimento do boleto é ${paymentData.boleto_expiration_date &&
                      formatDate(paymentData.boleto_expiration_date)}.`}
                  </li>
                  <li>
                    O pagamento é confirmado em até 3 dias úteis após efetuado o
                    pagamento do boleto.
                  </li>
                  {paymentData.customer && (
                    <li>
                      {`Um comprovante de pagamento será enviado para
                      ${paymentData.customer.email} após a confirmação de
                      pagamento.`}
                    </li>
                  )}
                </List>
              </Information>
              <InfoPayment>
                {paymentData.customer && (
                  <div>
                    <Title className="infoPayment">
                      Aproveite para avisar o {account.display_name} o dia em
                      que pretende efetuar o pagamento do boleto
                    </Title>
                  </div>
                )}
                <div className="wrapperBlockQuote">
                  <p>Pretendo efetuar o pagamento</p>
                  <Select
                    name=""
                    className="payment-date"
                    options={options}
                    placeholder="Selecione uma opção"
                    onChange={e => setDate(e.target.value)}
                  />
                </div>
                <div>
                  {notification === 'sended' ? (
                    <Sended>
                      <Icon
                        kind="bold"
                        group="interface-essential"
                        category="form-validation"
                        file="check-circle-1-alternate.svg"
                        size="20"
                        color="#50aff4"
                      />
                      Enviado
                    </Sended>
                  ) : (
                    <Button
                      loading={loading}
                      disabled={loading}
                      type="primary"
                      style={{
                        backgroundColor: '#50AFF4',
                        margin: '0 auto',
                        height: 38,
                        width: 215,
                      }}
                      onClick={Notify}
                    >
                      Enviar aviso
                    </Button>
                  )}
                </div>
              </InfoPayment>
            </Content>
          </Box>
          <Footer>
            <p>
              O Alboom Pay é 100% seguro. Processamos o pagamento através do
              Pagar.me, empresa do grupo Stone e líder em pagamentos online no
              Brasil.
            </p>

            <p className="secure">
              <img
                className="logo"
                src="https://bifrost.alboompro.com/products/alboompay/AlboomPay2022-full.svg"
                alt="AlboomPay"
              />
              | Pagamentos online 100% seguro
            </p>
          </Footer>
        </Container>
      )}
    </Body>
  );
}
