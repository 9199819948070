import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const Wrapper = styled.div`
  max-width: 1200px;
  width: 100%;
  padding: 66px 50px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;

  @media screen and (max-width: 1024px) {
    padding: 15px;
  }

  @media screen and (max-width: 930px) {
    flex-direction: column;
  }
`;
