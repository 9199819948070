export default {
  cpf: [
    /\d/,
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
  ],
  cnpj: [
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    /\d/,
    '/',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
  ],
  cep: [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/],
  cartão: [
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ],
  expiry: [/[0-9]/, /\d/, '/', /\d/, /\d/],
  cvc: [/[0-9]/, /\d/, /\d/],
  name: [/\W{1,10}$/],
  phone: [
    '(',
    /\d/,
    /\d/,
    ')',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d?/,
    '-',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ],
};

export const maskCVV = value => {
  if (!value) return value;
  const onlyNums = value.replace(/[^\d]/g, '');

  return onlyNums.slice(0, 4);
};

export const maskPhone = value => {
  if (!value) return value;
  const onlyNums = value.replace(/[^\d]/g, '');

  if (onlyNums.length <= 2) return onlyNums;

  if (onlyNums.length <= 6)
    return `(${onlyNums.slice(0, 2)}) ${onlyNums.slice(2, 6)}`;

  if (onlyNums.length <= 10)
    return `(${onlyNums.slice(0, 2)}) ${onlyNums.slice(2, 6)}-${onlyNums.slice(
      6,
      10
    )}`;

  return `(${onlyNums.slice(0, 2)}) ${onlyNums.slice(2, 7)}-${onlyNums.slice(
    7,
    11
  )}`;
};

export const maskCPForCNPJ = value => {
  if (!value) return value;
  const onlyNums = value.replace(/[^\d]/g, '');
  if (onlyNums.length <= 3) return onlyNums;

  if (onlyNums.length <= 6)
    return `${onlyNums.slice(0, 3)}.${onlyNums.slice(3, 6)}`;

  if (onlyNums.length <= 9)
    return `${onlyNums.slice(0, 3)}.${onlyNums.slice(3, 6)}.${onlyNums.slice(
      6,
      9
    )}`;

  if (onlyNums.length <= 11)
    return `${onlyNums.slice(0, 3)}.${onlyNums.slice(3, 6)}.${onlyNums.slice(
      6,
      9
    )}-${onlyNums.slice(9, 11)}`;

  if (onlyNums.length <= 12)
    return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2, 5)}.${onlyNums.slice(
      5,
      8
    )}/${onlyNums.slice(8, 12)}`;

  return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2, 5)}.${onlyNums.slice(
    5,
    8
  )}/${onlyNums.slice(8, 12)}-${onlyNums.slice(12, 14)}`;
};

export const maskExpiry = value => {
  if (!value) return value;
  const onlyNums = value.replace(/[^\d]/g, '');

  if (onlyNums.length <= 2) return onlyNums;

  if (onlyNums.length <= 4)
    return `${onlyNums.slice(0, 2)}/${onlyNums.slice(2, 4)}`;

  if (onlyNums.length <= 6)
    return `${onlyNums.slice(0, 2)}/${onlyNums.slice(4, 6)}`;

  return `${onlyNums.slice(0, 2)}/${onlyNums.slice(2, 4)}`;
};
