import { keyframes } from 'styled-components';

export const fadeDown = keyframes`

0% {
  opacity: 0; transform: translateY(-10px);
}

100% {
  opacity: 1; transform: translateY(0px);
}
`;

export const fadeUp = keyframes`
  0% {
    opacity: 0; transform: translateY(30px);
  }

  100% {
    opacity: 1; transform: translateY(0px);
  }
`;
