const utils = {
  currencyFormat: amount => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 2,
    }).format(amount);
  },
  installments: (amount, maxInstallments) => {
    const installmentAmount = [];
    // eslint-disable-next-line no-plusplus
    for (let x = 1; x <= maxInstallments; x++) {
      const i = amount / x / 100;
      installmentAmount.push({
        id: x,
        title: `${x}x de ${utils.currencyFormat(i)}`,
      });
    }
    return installmentAmount;
  },
};

export default utils;
