import { takeLatest, call, all, put } from 'redux-saga/effects';
import history from '../../../services/history';
import api from '../../../services/api';
import { signInSuccess, signInFailure } from './actions';

export function* signIn({
  payload: { username: nickname, orderID, isPaymentButton },
}) {
  try {
    const {
      data: { data: response },
    } = yield call(
      api.get,
      isPaymentButton
        ? `/payment-button/${nickname}/${orderID}`
        : `/payment-links/${nickname}/${orderID}`
    );
    const { username } = response.orders.account;

    if (username !== nickname) throw Error('Username incorreto');

    yield put(signInSuccess(response));
  } catch (err) {
    if (err.response.data.data.message === 'payment link already paid.') {
      yield put(signInFailure({ message: 'link_paid' }));
    } else {
      history.push('/');
      yield put(signInFailure({ message: err.response.data.data.message }));
    }
  }
}

export default all([takeLatest('@auth/SIGN_IN_REQUEST', signIn)]);
