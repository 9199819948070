import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  background: #fff;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
  height: 100vh;

  div {
    display: flex;

    max-width: 300px;
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;

    svg {
    }
  }
`;
