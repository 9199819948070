import { createGlobalStyle } from 'styled-components';
import media from './media';
import 'react-credit-cards/es/styles-compiled.css';
import 'react-toastify/dist/ReactToastify.css';
import { fadeDown } from './animations';

export default createGlobalStyle`

  @media print {
    header > * { display: none !important; }
    @page { margin: 0; }
  }

  html {
    font-size: 62.5%;/*10px*/ 
    box-sizing: border-box;
    line-height: 1.15;

    @media ${media.largest} {
      font-size: 60%;
    }

    @media ${media.large} {
      font-size: 57.5%;
    }

    @media ${media.small} {
      font-size: 55%;
    }
  }

  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  body {
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
    background-color: #f6fafe;
    font-family: 'Poppins', sans-serif !important;
  }

  body, input, textarea {
    font-size: 16px;
    box-sizing: inherit;
  }

  input, textarea {
    background: #fcfbfc;
  }

  a, button {
    outline: none;
  }

  h1, h2, h3, h4, h5 {
    padding: 12px 0;
    font-weight: 500;
  }

  h1 {
    font-size: 42px;
    line-height: 50px;
  }

  h2 {
    font-size: 36px;
    line-height: 40px;
  }

  h3 {
    font-size: 30px;
    line-height: 36px;
  }

  h4 {
    font-size: 26px;
    line-height: 32px;
  }

  h5 {
    font-size: 24px;
    line-height: 30px;
  }

  .modal-body{
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    min-height: 100vh;
    background: rgb(255, 255, 255);
  }

  .modal-body2{
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    min-height: 100vh;
    animation: ${fadeDown} 0.7s cubic-bezier(0.65, 0.05, 0.36, 1);
  }

.rccs {
  @media ${media.small} {
    max-width: 260px;
    display: flex;
    width: 100%;
  }
}

div,
p,
span,
button,
input,
select,
textarea {
  font-family: 'Poppins', sans-serif;
}


`;
