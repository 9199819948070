/* eslint-disable camelcase */
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Pusher from 'pusher-js';

import Button from '@alboompro/boom-components/general/button';
import QRCode from 'qrcode.react';

import Icon from '@alboompro/boom-components/general/icon';
import history from '../../../services/history';

import Load from '../../Loading';
import Box from '../../Box';
import Countdown from '../../Countdown';

import {
  Body,
  Container,
  Content,
  Information,
  Footer,
  Title,
  InfoContainer,
  QRCodeContainer,
  Step,
  StepNumber,
} from './styles';

export default function Receipt({ pixData }) {
  const {
    receipt: { transaction: paymentData },
  } = useSelector(state => state.payment);
  const { fullname } = useSelector(state => state.auth.orders.account);

  const { payment_id, pix_qr_code, date_updated } = paymentData || pixData;
  const isLoading = (!payment_id || !pix_qr_code || !date_updated) && !fullname;

  const pusher = new Pusher('yHyUphXnvg', {
    wsHost: 'push.alboompro.com',
    wssPort: 443,
    enabledTransports: ['ws'],
    disabledTransports: ['flash'],
    encrypted: true,
  });

  useEffect(() => {
    if (isLoading) {
      history.push('/');
    }
  }, [paymentData, fullname]);

  useEffect(() => {
    if (isLoading) {
      history.push('/');
    }

    const channel = pusher.subscribe(`pix-status-${payment_id}`);

    channel.bind('pix-status', data => {
      if (data.status === 'paid') {
        history.push('/pix/payment/paid');
      }
    });

    return () => {
      pusher.unsubscribe(`pix-status-${payment_id}`);
    };
  }, []);

  return (
    <Body>
      {isLoading ? (
        <Load />
      ) : (
        <Container id="no-print">
          <Box
            title={
              pixData
                ? 'Um Pix já foi gerado para este pagamento'
                : 'Agora só falta você pagar!'
            }
            icon={
              <Icon
                kind="bold"
                group="interface-essential"
                category="time"
                file="time-clock-circle-alternate.svg"
                size="32"
                color="#44ADF8"
              />
            }
          >
            <Content>
              <Information>
                <Title>
                  Pagar com Pix é rápido e seguro! É só seguir estes passos:
                </Title>
                <InfoContainer>
                  <QRCodeContainer>
                    <div className="qrcode-title">Pix QR Code</div>
                    <div className="qrcode-expires-in">
                      O pagamento expira em{' '}
                      {window.innerWidth >= 768 && (
                        <Countdown initialDate={date_updated} hours={4} />
                      )}
                    </div>
                    <QRCode size={200} value={pix_qr_code} />
                  </QRCodeContainer>
                  <div className="steps-container">
                    <Step>
                      <StepNumber>
                        <div>1</div>
                      </StepNumber>
                      <div className="step-content">
                        <b>Abra o app</b> do seu banco ou instituição financeira
                        e entre no ambiente <b>Pix</b>.
                      </div>
                    </Step>
                    <Step>
                      <StepNumber>
                        <div>2</div>
                      </StepNumber>
                      <div className="step-content">
                        {window.innerWidth >= 768 ? (
                          <>
                            <div>
                              Escolha a opção <b>Pagar com QR Code</b> e
                              escaneie o código ao lado.
                            </div>
                            <div className="step-details">
                              Caso você não tenha acesso à câmera, você pode
                              copiar o codigo de pagamento abaixo:
                            </div>
                          </>
                        ) : (
                          <div className="step-mobile">
                            Escolha a opção <b>Pagar com código Pix</b>, copie o
                            código abaixo e cole no app.
                          </div>
                        )}
                        <div>
                          <Button
                            onClick={() => {
                              navigator.clipboard.writeText(pix_qr_code);
                            }}
                            style={{
                              backgroundColor: '#ffffff',
                              border: '1px solid #44ADF8',
                              borderRadius: '3px',
                              height: 30,
                            }}
                            textStyle={{
                              color: '#44ADF8',
                              fontSize: 13,
                            }}
                          >
                            Copiar código
                          </Button>
                        </div>
                      </div>
                    </Step>
                    <Step>
                      <StepNumber>
                        <div>3</div>
                      </StepNumber>
                      <div className="step-content">
                        <div>Confirme as informações e finalize a compra.</div>
                      </div>
                    </Step>
                    <div className="steps-info">
                      <div className="steps-info-title">Importante</div>
                      <span>
                        O pagamento será para <b>Alboom</b> (Pagar.me) em nome
                        de <b>{fullname}</b>
                      </span>
                    </div>
                    {window.innerWidth < 768 && (
                      <div className="qrcode-expires-in">
                        O pagamento expira em{' '}
                        <Countdown initialDate={date_updated} hours={4} />
                      </div>
                    )}
                  </div>
                </InfoContainer>
              </Information>
            </Content>
          </Box>
          <Footer>
            <p>
              O Alboom Pay é 100% seguro. Processamos o pagamento através do
              Pagar.me, empresa do grupo Stone e líder em pagamentos online no
              Brasil.
            </p>

            <p className="secure">
              <img
                className="logo"
                src="https://bifrost.alboompro.com/products/alboompay/static/img/alboom-pay-black.svg"
                alt="AlboomPay"
              />
              | Pagamentos online 100% seguro
            </p>
          </Footer>
        </Container>
      )}
    </Body>
  );
}
