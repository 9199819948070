import { combineReducers } from 'redux';

import auth from './auth/reducer';
import payment from './payment/reducer';
import payer from './payer/reducer';

export default combineReducers({
  auth,
  payment,
  payer,
});
