/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';

import Header from '../components/Header';

export const HeaderRoute = ({ path, exact, component: Component }) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <Route
      path={path}
      exact={exact}
      render={() => (
        <div>
          <Header isVisible={isVisible} setIsVisible={setIsVisible} />
          <Component
            isVisible={path === '/:username/:orderID' && isVisible}
            setIsVisible={path === '/:username/:orderID' && setIsVisible}
          />
        </div>
      )}
    />
  );
};

HeaderRoute.propTypes = {
  path: PropTypes.string.isRequired,
  exact: PropTypes.bool.isRequired,
  component: PropTypes.element.isRequired,
};
