/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
import React, { useState, useRef } from 'react';
import Icon from '@alboompro/boom-components/general/icon';
import { useSelector } from 'react-redux';
import { ReactComponent as FacebookIcon } from '../../assets/Facebook.svg';
import { ReactComponent as InstagramIcon } from '../../assets/Instagram.svg';
import { ReactComponent as WhatsAppIcon } from '../../assets/WhatsApp.svg';
import { ReactComponent as LinkedInIcon } from '../../assets/LinkedIn.svg';
import { ReactComponent as GoogleBusinessIcon } from '../../assets/GoogleBusiness.svg';
import {
  Wrapper,
  Header,
  Content,
  Information,
  SocialMediaIcon,
} from './styles';

const ProfileTab = ({ isVisible, setIsVisible }) => {
  const { account } = useSelector(state => state.auth.orders);
  const [isScrolled, setIsScrolled] = useState();
  const tabContent = useRef();

  const {
    address,
    avatar_url,
    company_description: companyDescription,
    doc,
    display_name,
    type,
    email,
    contact_email,
    exchange_url,
    facebook,
    fullname,
    google_business: googleBusiness,
    have_website: haveWebsite,
    instagram,
    linkedin,
    number,
    phone,
    policy_url,
    privacy_url,
    verify_status: verifyStatus,
    website,
    whatsapp,
  } = account || {};

  const firstInitial = fullname ? fullname.split(' ')[0].substr(0, 1) : '..';
  const secondInitial = fullname ? fullname.split(' ')[1].substr(0, 1) : '.';
  const initials = (firstInitial + secondInitial).toUpperCase();

  const cpfFormatted = doc
    ? doc.length === 11
      ? `${doc.substr(0, 3)}.${doc.substr(3, 3)}.${doc.substr(
          6,
          3
        )}-${doc.substr(9, 2)}`
      : `${doc.substr(0, 2)}.${doc.substr(2, 3)}.${doc.substr(
          5,
          3
        )}/${doc.substr(8, 4)}-${doc.substr(12, 2)}`
    : 'Carregando...';

  const closeTab = () => {
    document.getElementById('profile-tab').animate(
      [
        { transform: 'translateY(0px)', opacity: 1 },
        { transform: 'translateY(30px)', opacity: 0 },
      ],
      {
        duration: 300,
        easing: 'cubic-bezier(0.55, 0.3, 0.5, 0.9)',
      }
    );
    setTimeout(() => {
      setIsVisible(false);
    }, 290);
  };

  const primaryEmail =
    contact_email && contact_email != '' ? contact_email : email;

  return (
    <>
      {isVisible && (
        <Wrapper id="profile-tab">
          <Header isScrolled={isScrolled}>
            {isScrolled ? (
              <>
                <div className="header-image">
                  {avatar_url ? (
                    <img
                      src={avatar_url}
                      alt={`Foto de perfil de ${fullname}`}
                      className="header-profile-picture"
                    />
                  ) : (
                    initials
                  )}
                  <Icon
                    kind="bold"
                    group="interface-essential"
                    category="form-validation"
                    file="check-circle-1.svg"
                    color={verifyStatus === 'verified' ? '#1B9DFA' : '#C4C4C4'}
                  />
                </div>
                <h1>{type && type === 'person' ? fullname : display_name}</h1>
              </>
            ) : (
              <section className="image">
                {avatar_url ? (
                  <img
                    src={avatar_url}
                    alt={`Foto de perfil de ${fullname}`}
                    className="profile-picture"
                  />
                ) : (
                  initials
                )}
                <Icon
                  kind="bold"
                  group="interface-essential"
                  category="form-validation"
                  file="check-circle-1.svg"
                  color={verifyStatus === 'verified' ? '#1B9DFA' : '#C4C4C4'}
                />
              </section>
            )}
            <button type="button" onClick={() => closeTab()}>
              &#10005;
            </button>
          </Header>
          <Content
            isScrolled={isScrolled}
            isVerified={verifyStatus === 'verified'}
            ref={tabContent}
            onScroll={() => {
              if (tabContent.current.scrollTop >= 5) setIsScrolled(true);
              else setIsScrolled(false);
            }}
          >
            {verifyStatus !== 'verified' && (
              <span className="notVerifiedAccount">Conta não verificada</span>
            )}

            {!isScrolled && (
              <h1>{type && type === 'person' ? fullname : display_name}</h1>
            )}
            <p>{companyDescription}</p>
            <div>
              <h1>Informações</h1>
              {doc && (
                <Information title={type && type === 'person' ? 'CPF' : 'CNPJ'}>
                  <span className="itemInformation">{cpfFormatted}</span>
                </Information>
              )}
              {type && type !== 'person' && (
                <Information title="Razão Social">
                  <span className="itemInformation">{fullname}</span>
                </Information>
              )}
              {address && number && (
                <Information title="Endereço">
                  <span className="itemInformation">
                    {address}, {number}
                  </span>
                </Information>
              )}
              {primaryEmail && (
                <Information title="E-mail" className="email" color="#2E2E2E">
                  <a
                    className="itemInformation"
                    href={`mailto:${primaryEmail}`}
                  >
                    {primaryEmail}
                  </a>
                </Information>
              )}
              {phone && (
                <Information title="Telefone">
                  <span className="itemInformation">{phone}</span>
                </Information>
              )}
              {website === '' || !haveWebsite ? (
                <Information title="Site" color="#2e2e2e">
                  <span className="itemInformation">Não possui website</span>
                </Information>
              ) : (
                <Information title="Site" className="website" color="#1b9dfa">
                  <a className="itemInformation" href={website}>
                    {website.replace('https://', '')}
                  </a>
                </Information>
              )}
              {(privacy_url || exchange_url || policy_url) && (
                <Information title="Termos e Políticas" color="#838383">
                  <ul>
                    {privacy_url && (
                      <li>
                        <a href={privacy_url}>Privacidade</a>
                      </li>
                    )}
                    {exchange_url && (
                      <li>
                        <a href={exchange_url}>Trocas e devoluções</a>
                      </li>
                    )}
                    {policy_url && (
                      <li>
                        <a href={policy_url}>Termos gerais</a>
                      </li>
                    )}
                  </ul>
                </Information>
              )}
            </div>

            {(facebook ||
              instagram ||
              whatsapp ||
              linkedin ||
              googleBusiness) && (
              <div>
                <h1 className="margin-8">Redes sociais</h1>
                <main>
                  {facebook && (
                    <SocialMediaIcon
                      color="#5067AE"
                      href={facebook}
                      target="_blank"
                    >
                      <FacebookIcon />
                    </SocialMediaIcon>
                  )}

                  {instagram && (
                    <SocialMediaIcon
                      color="#F435A8"
                      href={instagram}
                      target="_blank"
                    >
                      <InstagramIcon />
                    </SocialMediaIcon>
                  )}

                  {whatsapp && (
                    <SocialMediaIcon
                      color="#39E47A"
                      href={`https://api.whatsapp.com/send?phone=55${whatsapp
                        .replace('(', '')
                        .replace(')', '')
                        .replace(' ', '')
                        .replace('-', '')}`}
                      target="_blank"
                    >
                      <WhatsAppIcon />
                    </SocialMediaIcon>
                  )}

                  {linkedin && (
                    <SocialMediaIcon
                      color="#1F74B3"
                      href={linkedin}
                      target="_blank"
                    >
                      <LinkedInIcon />
                    </SocialMediaIcon>
                  )}

                  {googleBusiness && (
                    <SocialMediaIcon
                      color="#556CDC"
                      href={googleBusiness}
                      target="_blank"
                    >
                      <GoogleBusinessIcon />
                    </SocialMediaIcon>
                  )}
                </main>
              </div>
            )}

            <a href="https://www.alboompro.com/products/alboom-pay/">
              Saiba mais sobre o Alboom Pay
            </a>
          </Content>
        </Wrapper>
      )}
    </>
  );
};

export default ProfileTab;
