/* eslint-disable no-empty */
/* eslint-disable camelcase */

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Icon from '@alboompro/boom-components/general/icon';

import CardForm from '../../../components/CardForm';
import InvoiceForm from '../../../components/InvoiceForm';
import PixForm from '../../../components/PixForm';
import RadioInput from '../../../components/RadioInput';
import Box from '../../../components/Box';
import Footer from '../../../components/Footer';
import { Pix, BarCode, CreditCard } from '../../../components/BoomIcons';
import { setPayer } from '../../../store/modules/payer/actions';

import { Container, Title, RadioGroup } from './styles';

export default function Form({ fixed }) {
  const [data, setData] = useState({});
  const { account } = useSelector(state => state.auth.orders);
  const dispatch = useDispatch();
  const allMethodsEnabled =
    account &&
    account.payments_settings.credit_card.enabled &&
    account.payments_settings.invoice.enabled &&
    account.payments_settings.pix.enabled;

  useEffect(() => {
    if (account) {
      setData({
        avatar: account.avatar_url,
        name: account.fullname,
        option:
          (account.payments_settings.credit_card.enabled && 'cc') ||
          (account.payments_settings.invoice.enabled && 'in') ||
          (account.payments_settings.pix &&
            account.payments_settings.pix.enabled &&
            'pix'),
      });

      dispatch(
        setPayer({
          payment_type:
            (account.payments_settings.credit_card.enabled && 'cc') ||
            (account.payments_settings.invoice.enabled && 'in') ||
            (account.payments_settings.pix &&
              account.payments_settings.pix.enabled &&
              'pix'),
        })
      );
    }
  }, [account, dispatch]);

  const handleInputChange = value => {
    setData({ ...data, option: value });
    dispatch(setPayer({ payment_type: value }));
  };

  return (
    <Container fixed={fixed}>
      <Box width="735px">
        <Title>Como gostaria de pagar?</Title>
        <RadioGroup
          justifyContent={allMethodsEnabled ? 'space-between' : 'flex-start'}
        >
          {account && account.payments_settings.credit_card.enabled && (
            <RadioInput
              hideInput
              type="radio"
              value="cc"
              checked={data.option === 'cc'}
              name="creditcard"
              onClick={() => handleInputChange('cc')}
              className={`cc ${data.option === 'cc' && 'active'}`}
            >
              {data.option === 'cc' && (
                <div className="check-icon">
                  <Icon
                    kind="bold"
                    group="interface-essential"
                    category="form-validation"
                    file="check.svg"
                    size="12"
                    color="#ffffff"
                  />
                </div>
              )}
              <CreditCard
                color={data.option === 'cc' ? '#44ADF8' : '#595959'}
                width="18"
                height="18"
              />
              <span>Cartão de crédito</span>
            </RadioInput>
          )}
          {account && account.payments_settings.invoice.enabled && (
            <RadioInput
              hideInput
              type="radio"
              value="in"
              checked={data.option === 'in'}
              name="invoice"
              disabled
              onClick={() => handleInputChange('in')}
              className={`in ${data.option === 'in' && 'active'}`}
              margin={allMethodsEnabled ? '0 8px' : '0'}
            >
              {data.option === 'in' && (
                <div className="check-icon">
                  <Icon
                    kind="bold"
                    group="interface-essential"
                    category="form-validation"
                    file="check.svg"
                    size="12"
                    color="#ffffff"
                  />
                </div>
              )}
              <BarCode
                color={data.option === 'in' ? '#44ADF8' : '#595959'}
                width="18"
                height="18"
              />
              <span>Boleto bancário</span>
            </RadioInput>
          )}
          {account &&
            account.payments_settings.pix &&
            account.payments_settings.pix.enabled && (
              <RadioInput
                hideInput
                type="radio"
                value="pix"
                checked={data.option === 'pix'}
                name="pix"
                disabled
                onClick={() => handleInputChange('pix')}
                className={`pix ${data.option === 'pix' && 'active'}`}
              >
                {data.option === 'pix' && (
                  <div className="check-icon">
                    <Icon
                      kind="bold"
                      group="interface-essential"
                      category="form-validation"
                      file="check.svg"
                      size="12"
                      color="#ffffff"
                    />
                  </div>
                )}

                <Pix
                  color={data.option === 'pix' ? '#44ADF8' : '#595959'}
                  width="18"
                  height="18"
                />
                <span>Pix</span>
              </RadioInput>
            )}
        </RadioGroup>

        {data.option === 'cc' && <CardForm />}
        {data.option === 'in' && <InvoiceForm />}
        {data.option === 'pix' && <PixForm />}
      </Box>
      <Footer />
    </Container>
  );
}

Form.propTypes = {
  fixed: PropTypes.bool.isRequired,
};
