/* eslint-disable no-nested-ternary */
import styled from 'styled-components';
import { fadeUp } from '../../styles/animations';

export const Wrapper = styled.div`
  position: fixed;
  right: 20px;
  bottom: 20px;
  width: 325px;
  height: auto;
  max-height: 518px;
  background-color: white;
  border: 1px solid #dedede;
  box-sizing: border-box;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.17);
  border-radius: 4px;
  animation: ${fadeUp} 0.3s cubic-bezier(0.55, 0.3, 0.5, 0.9);
  z-index: 1;

  @media (max-width: 768px) {
    & {
      width: 100%;
      height: 100%;
      max-height: 100%;
      right: 0px;
      bottom: 0px;
    }
  }
`;

export const Header = styled.header`
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: #1b9dfa;
  padding: 11px 18px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  position: relative;

  .image {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    border: 1px solid #ffffff;
    border-radius: 50%;
    background: #d467e6;
    font-size: 19px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    color: #ffffff;
    position: absolute;
    top: 30px;
    left: 50%;
    transform: translateX(-50%);

    img.profile-picture {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
    }

    img:not(.profile-picture) {
      position: absolute;
      bottom: -2px;
      right: -2px;
      width: 20px;
      height: 20px;
      border: 1px solid #ffffff;
      background-color: white;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  div {
    width: 33px;
    height: 33px;
    border: 1px solid #ffffff;
    border-radius: 50%;
    background: #d467e6;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0em;
    text-align: center;
    vertical-align: middle;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    img.header-profile-picture {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }

    img:not(.header-profile-picture) {
      position: absolute;
      bottom: -2px;
      right: -2px;
      width: 13px;
      height: 13px;
      border: 1px solid #ffffff;
      background-color: white;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  span {
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 11px;
    letter-spacing: 0em;
    color: #3d3d3d;
  }

  h1 {
    flex: 1;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
    color: #ffffff;
    margin-left: 11px;
    position: static;
  }

  button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 6px 8px 2px;
    font-size: 18px;
    font-weight: bold;
    color: #ffffff;
    border-radius: 3px;

    &:hover {
      background-color: #5fbafc;
    }
  }
`;

export const Content = styled.article`
  padding: ${props => (props.isScrolled ? '30px' : '22px 36px')};
  height: 458px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 1px;
    background-color: #fff;
  }

  span.notVerifiedAccount {
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 11px;
    letter-spacing: 0em;
    text-align: center;
    color: #3d3d3d;
    display: ${props => (props.isScrolled ? 'none' : 'block')};
    margin-top: 24px;
  }

  .itemInformation {
    max-width: 70%;
    word-break: break-all;
    display: inline-flex;
    justify-content: end;
  }

  h1 {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: center;
    margin-top: ${props => (props.isVerified ? '16px' : 'unset')};
  }

  p {
    margin: 0;
    padding: 0;
    max-width: 271px;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 13px;
    letter-spacing: 0em;
    text-align: center;
    color: #646464;
    padding-bottom: 23px;
    border-bottom: 1px solid #eeeeee;
    transform: matrix(1, 0, 0, 1, 0, 0);
  }

  > div {
    h1 {
      margin-top: 13px;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 15px;
      letter-spacing: 0em;
      text-align: left;
      color: #000000;
      margin-bottom: 30px;

      &.margin-8 {
        margin-bottom: 8px;
      }
    }

    main {
      display: flex;
      padding-bottom: 23px;
      border-bottom: 1px solid #eeeeee;
      transform: matrix(1, 0, 0, 1, 0, 0);

      a + a {
        margin-left: 25px;
      }
    }
  }

  > a {
    display: block;
    margin-top: 12px;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 100%;
    color: #8a8a8a;
    text-align: right;
  }
`;

export const Information = styled.span`
  display: block;
  position: relative;
  width: 100%;
  text-align: right;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 13px;
  letter-spacing: 0em;
  text-align: right;
  color: ${props => props.color || '#2e2e2e'};
  padding-bottom: 17px;
  border-bottom: 1px solid #f4f4f4;
  transform: matrix(1, 0, 0, 1, 0, 0);

  li {
    list-style: none;

    & + li {
      margin-top: 12px;
    }
  }

  a {
    color: ${props => props.color};
  }
  
  &:before {
    content: '${props => props.title}';
    display: inline-block;
    position: absolute;
    left: 0;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 13px;
    letter-spacing: 0em;
    text-align: left;
    color: #717171;
  }

  &.email,
  &.website {
    text-decoration-line: underline;
  }

  & + span {
    margin-top: 17px;
  }

  &:last-child {
    padding-bottom: 34px;
  } 
`;

export const SocialMediaIcon = styled.a`
  background-color: ${props => props.color ?? '#000000'};
  width: 33px;
  height: 33px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
`;
