import styled from 'styled-components';

import colors from '../../styles/colors';

export const Label = styled.label`
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  user-select: none;

  p {
    font-size: 14px;
    color: ${colors.darkgray};

    a {
      color: ${colors.blue};
    }
  }

  span.label {
    display: flex;
    align-items: center;
    position: relative;

    svg {
      margin-left: 0.6rem;
      fill: ${colors.darkgray};

      &:hover ~ div {
        display: block;
      }
    }

    > div {
      position: relative;
    }
  }

  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    left: 7px;
    top: 0;
    height: 17px;
    width: 17px;
    background-color: #ffffff;
    border: 1px solid #ccc;
    border-radius: 2px;
  }

  input:checked ~ .checkmark {
    background-color: ${colors.lightblue};
  }

  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  input:checked ~ .checkmark:after {
    display: block;
  }

  .checkmark:after {
    left: 5px;
    width: 3px;
    height: 8px;
    top: 1px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }

  > input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    left: 10px;
    margin: 5px 0 0 0;
  }
`;

export const Message = styled.div`
  display: none;
  position: absolute;
  background: #fff;
  color: #9c9a9a;
  font-size: 1.2rem;
  min-width: 20rem;
  right: 2.5rem;
  top: 1rem;
  padding: 1rem;
  border-radius: 3px;
  box-shadow: 0 0 7px 0 rgba(6, 6, 6, 0.08);
`;
