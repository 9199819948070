import axios from 'axios';

const viacep = axios.create({
  baseURL: process.env.REACT_APP_VIA_CEP_URL,
});

const fetchCEPData = async cep => {
  return await viacep.get(`/${cep}/json/`);
};

export default fetchCEPData;
