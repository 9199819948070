import styled from 'styled-components';
import { Form, Input } from '@rocketseat/unform';
import { fadeDown } from './animations';
import media from './media';
import colors from './colors';

export const Container = styled.div`
  .d-none {
    display: none;
  }

  @media ${media.medium} {
    max-width: 750px;
    margin-left: 0;
  }
`;

export const Col = styled.div`
  width: ${props => props.width};
  display: inline-block;
  margin-right: ${props => (props.mr ? props.mr : '0px')};

  @media ${media.small} {
    width: ${props => (props.width_mb ? props.width_mb : '100%')};
    display: inline-block;

    input {
      margin-bottom: 10px;
    }
  }
`;

export const Title = styled.div`
  font-size: 18px;
  font-weight: 500;
  color: #212121;
  padding-top: 25px;
`;

export const StyledForm = styled(Form)`
  max-width: 780px;
  width: 100%;
  padding: 10px 90px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  position: relative;

  @media screen and (max-width: 1024px) {
    padding: 0 40px;
  }

  button {
    margin: 2rem 0;
    width: 233px;

    span {
      display: flex;
      margin-top: 0;
    }
  }

  span {
    color: #f44336;
    margin-top: 5px;
    animation: ${fadeDown} 0.5s cubic-bezier(0.65, 0.05, 0.36, 1);
    display: table;
    font-size: 12px;
  }

  .__react_component_tooltip {
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);
    color: #938e8e;
    width: 203px;
  }

  .tooltip {
    display: inline-block;
    margin: 10px;
  }

  @media ${media.medium} {
    padding: 0px 10px;
  }
`;

export const StyledInput = styled(Input)`
  padding: 14px 15px;
  width: 100%;
  transition: all 0.2s ease-out;
  border: 1px solid #e8e8e8;
  border-radius: 3px;
  font-size: 14px;
  line-height: 1.2;
  color: ${colors.darkgray};

  &:focus {
    box-shadow: 0 0 2px 1px #44adf8;
  }

  &:focus &::placeholder {
    display: none;
  }

  &:after {
    content: ${props => (props.name ? props.name : 'null')};
    font-size: 14px;
    top: 1px;
    left: 0;
  }

  &:disabled {
    background: #f5f5f5;
  }
`;

export const RowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 0 25px;
  max-width: 800px;
  display: inline-block;
  position: relative;

  @media ${media.small} {
    display: inline;
  }

  .mask {
    padding: 14px 15px;
    width: 100%;
    transition: all 0.2s ease-out;
    border: 1px solid #e8e8e8;
    border-radius: 3px;
    font-size: 14px;
    line-height: 1.2;
    color: ${colors.darkgray};

    &:focus {
      box-shadow: 0 0 2px 1px #44adf8;
    }

    &:focus &::placeholder {
      display: none;
    }

    &:after {
      content: ${props => (props.name ? props.name : 'null')};
      font-size: 14px;
      top: 1px;
      left: 0;
    }
  }
`;

export const RowCenter = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  label {
    margin-right: 20px;
  }

  @media ${media.small} {
    label {
      margin-right: 0;
    }
  }
`;

export const RowLeft = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;

  label {
    margin-right: 20px;
    padding-left: 25px;
  }

  .checkmark {
    left: 0 !important;
  }

  @media ${media.small} {
    display: inline-block;
    label {
      margin-right: 0;
    }
  }
`;

export const Label = styled.label`
  display: block;
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 7px;
  color: ${colors.darkgray};
`;

export const UserTermsText = styled.p`
  font-size: 14px;
`;
