import styled from 'styled-components';
import colors from '../../styles/colors';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 335px;
  width: 100%;
  top: ${props => props.fixed && '10px;'};
  position: ${props => (props.fixed ? 'fixed;' : 'relative;')};

  @media screen and (max-width: 930px) {
    max-width: 100%;
    margin-bottom: 15px;
    position: relative;
  }

  hr {
    margin-bottom: 1.2em;
    border-top: 0.5px solid #eee;
  }

  .mt-3 {
    margin-top: 1.2em;
  }
`;

export const Details = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 30px;
`;

export const AccountContainer = styled.div`
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Title = styled.p`
  font-size: 14px;
  display: flex;
  align-items: center;
  color: #262626;

  &.total {
    margin-bottom: 0;
    font-weight: 500;
    font-size: 16px;
    color: #333333;
  }

  &.advanced {
    margin-bottom: 0;
    border-bottom: 1px solid #f4f4f4;
    padding-bottom: 10px;
  }
`;

export const AccountSubContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;

  p:first-child {
    font-size: 12px;
  }
`;

export const Account = styled.p`
  font-size: 14px;
  color: #262626;

  &.name {
    font-weight: 500;
    font-size: 16px;
    color: #1f1f1f;
  }

  a {
    color: #50aff4;

    &:hover {
      text-decoration: underline;
      cursor: pointer;
      color: #71bdf4;
    }
  }
`;

export const Description = styled.p`
  font-size: 14px;
  color: #1f1f1f;
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid ${colors.lightgray};
`;

export const TotalContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ItemContainer = styled.div`
  padding: 20px 0;
  border-bottom: 1px solid #f4f4f4;
`;

export const Item = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  p {
    font-size: 15px;
    color: #1f1f1f;
  }
`;

export const ItemPrice = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #3d3d3d;

  span {
    margin-left: 5px;
  }
`;

export const Info = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;

  &:first-child {
    margin-top: 25px;
  }

  p {
    font-size: 15px;
    display: flex;
    align-items: center;
    color: #424242;

    > span {
      margin-right: 5px;
    }
  }
`;

export const Total = styled.p`
  font-weight: 500;
  font-size: 16px;
  color: #333333;
`;

export const Footer = styled.div``;

export const Security = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 25px 0;

  p {
    font-size: 13px;
    line-height: 1;
    color: #494949;
    padding: 0 5px;
  }

  img.logo {
    max-width: 100%;
    margin-top: 0px;
    width: 90px;
  }

  @media screen and (max-width: 930px) {
    display: none;
  }
`;
