import styled from 'styled-components';

export const FlagContainer = styled.div`
  width: 320px;
  margin: 0 auto;

  h4 {
    font-size: 12px;
    margin: 10px 0;
    text-transform: uppercase;
    font-weight: 500;
  }
`;

export const Flags = styled.div`
  background: #e9e9e9;
  border-radius: 2px;
  width: 40px;
  height: 26px;

  display: flex;
  align-items: center;
  justify-content: center;

  margin-left: 5px;

  font-size: 11px;
  color: #656565;

  cursor: default;

  &:hover {
    .TipWrapper {
      visibility: visible;
      z-index: 3;
    }
  }
`;

export const BrandLists = styled.ul`
  display: flex;
  position: absolute;
  right: 10px;
  top: 34px;

  li {
    width: 40px;
    height: 26px;
    align-items: center;
    display: flex;
    opacity: 0;
    transition: opacity 0.2s linear;
    position: absolute;
    top: 0;
    right: 2px;

    img {
      max-width: 100%;
    }

    &.active {
      opacity: 1;
    }
  }
`;

export const FlagsList = styled.div`
  display: flex;
  align-items: center;

  img {
    height: 26px;
    margin-left: 5px;
  }
`;


export const TipWrapper = styled.div`
  position: absolute;
  visibility: hidden;
  top: 5px;
  right: 0px;
  transform: translate(45%, 30px);
  background-color: #ffffff;
  border: 1px solid #e4e4e4;
  padding: 12px 15px;
  border-radius: 5px;
  min-width: 380px;
  z-index: 3;
  font-size: 13px;
  line-height: 1.2;
  font-weight: 400;
  color: #282828;
  box-shadow: 1px 1px 7px rgba(0, 0, 0, 0.15);

  strong {
    display: block;
    font-weight: 500;
    margin-bottom: 2px;
  }

  @media screen and (max-width: 930px) {
    flex-direction: column;
    transform: translate(0, 30px);
  }
`;
