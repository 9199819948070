import React from 'react';
import PropTypes from 'prop-types';
// import { useSelector } from 'react-redux';

import { Container, Header, Title, IconContainer } from './styles';

function Box({ title, icon, children, width }) {
  return (
    <Container width={width}>
      {title && (
        <Header>
          {icon && <IconContainer>{icon}</IconContainer>}
          <Title>{title}</Title>
        </Header>
      )}
      <div>{children}</div>
    </Container>
  );
}

Box.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.element,
  children: PropTypes.element.isRequired,
  width: PropTypes.string,
};

Box.defaultProps = () => ({
  title: null,
});

export default Box;
