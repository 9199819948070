export function paymentRequest(sendData) {
  return {
    type: '@payment/PAYMENT_REQUEST',
    payload: { sendData },
  };
}

export function paymentSuccess(sendData) {
  return {
    type: '@payment/PAYMENT_SUCCESS',
    payload: { sendData },
  };
}

export function invoiceRequest(sendData) {
  return {
    type: '@payment/INVOICE_REQUEST',
    payload: { sendData },
  };
}

export function invoiceSuccess(sendData) {
  return {
    type: '@payment/INVOICE_SUCCESS',
    payload: { sendData },
  };
}

export function paymentFailure() {
  return {
    type: '@payment/PAYMENT_FAILURE',
  };
}

export function invoiceNotify(payload) {
  return {
    type: '@payment/INVOICE_NOTIFY',
    payload,
  };
}

export function invoiceNotifySuccess(payload) {
  return {
    type: '@payment/INVOICE_NOTIFY_SUCCESS',
    payload,
  };
}

export function invoiceNotifyFailure() {
  return {
    type: '@payment/INVOICE_NOTIFY_FAILURE',
  };
}

export function paymentIsIframe(isIframe) {
  return {
    type: '@payment/IS_IFRAME',
    payload: isIframe,
  };
}
