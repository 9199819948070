import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Payment from 'payment';

import { BrandLists, Flags, TipWrapper, FlagsList } from './styles';

const CardFlags = ({ number }) => {
  const brand = number.length >= 4 ? Payment.fns.cardType(number) : '';

  const brands = [
    {
      name: 'mastercard',
      icon: 'mastercard',
    },
    {
      name: 'visa',
      icon: 'visa',
    },
    {
      name: 'amex',
      icon: 'amex',
    },
    {
      name: 'dinersclub',
      icon: 'diners',
    },
    {
      name: 'elo',
      icon: 'elo',
    },
  ];

  // eslint-disable-next-line eqeqeq
  if (brand == '') {
    return (
      <BrandLists>
        <FlagsList>
          <img
            src="https://bifrost.alboompro.com/static/alboompay/icons/flags/mastercard.svg"
            alt=""
          />
          <img
            src="https://bifrost.alboompro.com/static/alboompay/icons/flags/visa.svg"
            alt=""
          />
          <img
            src="https://bifrost.alboompro.com/static/alboompay/icons/flags/elo.svg"
            alt=""
          />
          <img
            src="https://bifrost.alboompro.com/static/alboompay/icons/flags/amex.svg"
            alt=""
          />
        </FlagsList>
        <Flags>
          +5
          <TipWrapper className="TipWrapper">
            <strong>Bandeiras aceitas</strong>
            Visa, Master, Elo, Hiper, Aura, Dinners, Amex, JCB e Discover.
          </TipWrapper>
        </Flags>
      </BrandLists>
    );
  }
  return (
    <BrandLists>
      {brands.map(flag => {
        const FlagClasses = cx(`flag-${flag.name}`, {
          active: brand === flag.name,
        });

        return (
          <li key={`flag-${flag.name}`} className={FlagClasses}>
            <img
              src={`https://bifrost.alboompro.com/static/alboompay/icons/flags/${flag.icon}.svg`}
              alt=""
            />
          </li>
        );
      })}
    </BrandLists>
  );
};

CardFlags.propTypes = {
  number: PropTypes.string.isRequired,
};

CardFlags.defaultProps = () => ({
  brand: '',
});

export default CardFlags;
