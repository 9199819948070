import styled from 'styled-components';

export const Container = styled.div`
  max-width: 780px;
  width: 100%;
  margin-right: ${props => (props.fixed ? '365px' : '30px')};

  h2 {
    font-size: 22px;
    text-align: center;
    color: #292929;
  }

  @media screen and (max-width: 1024px) {
    margin-right: 15px;
  }

  @media screen and (max-width: 930px) {
    max-width: 100%;
    margin-right: 0;
  }
`;

export const Title = styled.p`
  font-weight: 500;
  font-size: 18px;
  color: #3f3f3f;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const RadioGroup = styled.div`
  margin: 0;
  padding: 0 90px 20px;
  display: flex;
  font-size: 14px;
  justify-content: ${props => props.justifyContent};
  gap: 20px;

  @media screen and (max-width: 1024px) {
    padding: 0 40px 20px;
  }

  .check-icon {
    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    left: -6px;
    top: -6px;

    border-radius: 50%;
    width: 20px;
    height: 20px;
    background-color: #44adf8;

    img {
      margin: 0;
    }
  }

  svg {
    margin-right: 8px;
    margin-left: 0;
  }

  span.label {
    position: unset;

    svg {
      margin-left: 0;
    }
  }

  label {
    align-items: center;
    justify-content: center;

    background: #ffffff;
    border: 1px solid transparent;
    box-sizing: border-box;
    border-radius: 3px;
    max-width: 280px;
    height: 50px;
    padding: 0 15px;
    position: relative;

    &:hover {
      background-color: #f2faff;
    }

    @media screen and (max-width: 768px) {
      max-width: 100%;
      justify-content: flex-start;
      padding: 0 30px;
    }
  }

  .cc {
    &.active {
      background-color: #f2faff;
      border: 1px solid #44adf8;
      color: #44adf8;
    }
  }

  .in {
    margin: ${props => props.margin};
    &.active {
      background-color: #f2faff;
      border: 1px solid #44adf8;
      color: #44adf8;
    }

    @media screen and (max-width: 768px) {
      margin: 0;
    }
  }

  .pix {
    min-width: 120px;
    svg {
      margin-right: 6px;
    }

    &.active {
      background-color: #f2faff;
      border: 1px solid #44adf8;
      color: #44adf8;
    }
  }

  @media (max-width: 768px) {
    padding: 10px;
    flex-direction: column;

    > label {
      flex: 1 1 auto;
      text-align: center;
      width: 100%;
      margin: 5px 0;
    }
  }
`;
