import React from 'react';

import PropTypes from 'prop-types';

const BarCode = ({ color, height, width }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 22 18"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 0H0.601639V16H0V0ZM1.20328 0H1.80492V16H1.20328V0ZM4.81566 0H5.4173V16H4.81566V0ZM16.5265 0H17.1282V16H16.5265V0ZM20.4417 0H21.0433V16H20.4417V0ZM24.238 0H24.8396V16H24.238V0ZM25.2164 0H25.8181V16H25.2164V0ZM26.1962 0H26.7978V16H26.1962V0ZM27.2973 0H27.8989V16H27.2973V0ZM28.3984 0H29V16H28.3984V0ZM8.78827 0H9.38991V16H8.78827V0ZM2.76805 0H3.85125V16H2.76805V0ZM9.91746 0H11.0007V16H9.91746V0ZM14.9375 0H16.0207V16H14.9375V0ZM17.5076 0H18.5908V16H17.5076V0ZM21.5466 0H22.6298V16H21.5466V0ZM6.01894 0H8.18535V16H6.01894V0ZM11.6342 0H13.3191V16H11.6342V0Z"
      fill={color}
    />
  </svg>
);

BarCode.propTypes = {
  color: PropTypes.string.isRequired,

  height: PropTypes.number.isRequired,

  width: PropTypes.number.isRequired,
};

export default BarCode;

