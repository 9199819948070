import React, { useEffect } from 'react';

import { useSelector } from 'react-redux';

import Currency from 'react-currency-formatter';

import moment from 'moment';
import 'moment/locale/pt-br';

import Icon from '@alboompro/boom-components/general/icon';
import Load from '../../Loading';
import Box from '../../Box';
import history from '../../../services/history';

import {
  Container,
  Body,
  Header,
  Content,
  Important,
  Info,
  InfoItem,
  Notification,
  Footer,
} from './styles';

export default function Receipt() {
  const {
    loading,
    receipt: { transaction: paymentData },
  } = useSelector(state => state.payment);

  const account = useSelector(
    state =>
      (state.auth.orders && state.auth.orders && state.auth.orders.account) ||
      {}
  );

  useEffect(() => {
    if (!paymentData) {
      history.push('/');
    }
  }, [paymentData]);

  return (
    <Body>
      {loading || !paymentData ? (
        <Load />
      ) : (
        <Container>
          <Box
            title="Pagamento realizado com sucesso!"
            icon={
              <Icon
                kind="bold"
                group="interface-essential"
                category="form-validation"
                file="check-circle-1-alternate.svg"
                size="32"
                color="#44ADF8"
              />
            }
          >
            <Header>
              <p>
                O pagamento de{' '}
                <span>
                  <Currency
                    quantity={paymentData.amount / 100}
                    decimal=","
                    currency="BRL"
                  />
                </span>{' '}
                para <span>{account.fullname}</span> foi aprovado.
              </p>
            </Header>

            <Content>
              <Important>
                <p>
                  <span>Importante</span>
                </p>
                <p>
                  Em seu extrato bancário a descrição será{' '}
                  <span>Alboom</span>
                </p>
              </Important>

              <Info>
                <InfoItem>
                  <p>Código do pagamento:</p>
                  <p>{paymentData.acquirer_id}</p>
                </InfoItem>

                <InfoItem>
                  <p>Realizado em:</p>
                  <p>
                    {moment(paymentData.date_updated).format(
                      'DD/MMM/YYYY [as] HH:MM'
                    )}
                  </p>
                </InfoItem>

                <InfoItem>
                  <p>Valor foi recebido por:</p>
                  <p>{account.fullname}</p>
                </InfoItem>
              </Info>

              <Notification>
                <p>Nenhuma ação adicional é necessária.</p>
                <p>
                  <span>{account.fullname}</span> já foi notificado do seu
                  pagamento e você receberá um comprovante no e-mail{' '}
                  {paymentData.customer.email}
                </p>
              </Notification>
              <p className="receipt">
                Caso queira imprimir este comprovante,{' '}
                <span aria-hidden="true" onClick={() => window.print()}>
                  clique aqui.
                </span>
              </p>
            </Content>
          </Box>
          <Footer>
            <p>
              O Alboom Pay é 100% seguro. Processamos o pagamento através do
              Pagar.me, empresa do grupo Stone e líder em pagamentos online no
              Brasil.
            </p>

            <p className="secure">
              <img
                className="logo"
                src="https://bifrost.alboompro.com/products/alboompay/static/img/alboom-pay-black.svg"
                alt="AlboomPay"
              />
              | Pagamentos online 100% seguro
            </p>
          </Footer>
        </Container>
      )}
    </Body>
  );
}
