import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";

import { useField } from "@rocketseat/unform";
import * as FontAwesome from "react-icons/md";

import { Label, Message } from "./styles";

export default function RadioInput({
  label,
  left,
  type,
  customMessage,
  infoSize,
  value,
  info,
  name,
  icon,
  size,
  iconStyle,
  children,
  ...rest
}) {
  const ref = useRef(null);
  const { fieldName, registerField, defaultValue, error } = useField(name);

  const InfoIcon = FontAwesome[info];

  useEffect(() => {
    if (!ref.current) return;
    registerField({
      name: fieldName,
      ref: ref.current,
      path: "value"
    });
  }, [ref.current, fieldName]); //eslint-disable-line

  return (
    <Label className="container" htmlFor={fieldName} icon={icon} left={left}>
      {error && <span>{error}</span>}

      <input
        value={value}
        name={fieldName}
        type="checkbox"
        ref={ref}
        id={fieldName}
        aria-label={fieldName}
        defaultValue={defaultValue}
        {...rest}
      />
      <span className="checkmark" />
      <span className="label">
        {children[0]}

        {children}
        <div>
          {info && <InfoIcon size={infoSize} />}
          <Message>{customMessage}</Message>
        </div>
      </span>
    </Label>
  );
}

RadioInput.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  icon: PropTypes.string,
  info: PropTypes.string,
  type: PropTypes.string,
  left: PropTypes.bool,
  customMessage: PropTypes.string,
  size: PropTypes.number,
  infoSize: PropTypes.number,
  iconStyle: PropTypes.objectOf(PropTypes.any),
  children: PropTypes.element,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

RadioInput.defaultProps = ({ name }) => ({
  icon: "",
  customMessage: "Hey Brother!",
  label: "",
  size: null,
  iconStyle: null,
  children: null
});
